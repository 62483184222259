import React from 'react'
import secureLocalStorage from 'react-secure-storage';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useGetSalesBillByIdQuery } from '../../../redux/CrmServices/SalesBillService';
import ProductionDeliveryDetails from './ProductionDeliveryDetails';
import { useGetPartyQuery } from '../../../redux/CrmServices/PartyMasterService';
import PrintHeader from './PrintHeader';
import TaxDetails from './TaxDetails';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
import PrintFooterOnly from './PrintFooter';
import PrintDetail from './PrintDetail';
require('number-to-text/converters/en-in');
const numberToText = require('number-to-text')

export default function PrintFormatSalesBillReport({ innerRef, id, taxTemplateId, poBillItems }) {
  console.log(poBillItems, "PoBillitems")

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }

  const { data: supplierList } =
    useGetPartyQuery({ params });
  const supplierData = supplierList?.data ? supplierList.data : []
  console.log(supplierData, "supplierData")

  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetSalesBillByIdQuery(id, { skip: !id });

  const from = {
    aliasName: "VISION GROUPS",
    name: "VISION GROUPS",
    address: "No 470 1 st floor opp h.p petrol bunk pn road pushpa Theader",
    near: " Near Richman hotel",
    pinCode: "641603",
    mobile: "India",
    gstNo: "GSTIN 33HAPPS7637H1ZZ",
    PlaceofSupply: "Tamilnadu(33)",
  }

  const fromAddress = {
    aliasName: "VISION GROUPS",
    name: "VISION GROUPS",
    address: "No 470 1 st floor opp h.p petrol bunk pn road near Richman hotel pushpa",
    gstNo: "Theader tirupur 641603",
    panNo: "641603",
    mobile: "India",
    gstNo: "GSTIN 33HAPPS7637H1ZZ",
    PlaceofSupply: "Tamilnadu(33)",
  }

  const { isLoading: isTaxHookDetailsLoading, ...taxDetails } = useTaxDetailsHook({
    poItems: (singleData?.data?.SalesBillItems || []).map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId, discountType: "percentage",
    discountValue: 0
  })
  if (!singleData) {
    return <div ref={innerRef}>No Data</div>;
  }
  function gstNo(supplierId, supplierData) {
    const matchingSupplier = supplierData.find(
      (supplier) => supplier.id === supplierId
    );

    return matchingSupplier || null;
  }

  return (
    //   <div  className="h-screen flex flex-col justify-between border-2 m-0 border-gray-700" id='poPrint' ref={innerRef}>
    //     <div>
    //     <div className="page-header pl-5 pt-1.5 font-times">
    //       
    //       </div>
    //        <PrintDetail singleData={singleData} from = {from} supplierData={supplierData} taxDetails={taxDetails} taxTemplateId={taxTemplateId}/>
    //  <PrintFooterOnly />
    //       {/* <div className="relative">
    //         <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
    //           <div className="bg-green-500 p-2 rounded-md text-white text-center">
    //             <p>Terms-Condition</p>
    //           </div>
    //           <div className="absolute top-full left-1/2 transform -translate-x-1/2">
    //             <svg className="h-4 w-4 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //               <path fill-rule="evenodd" d="M5.293 6.707a1 1 0 0 1 1.414-1.414L10 8.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4zM10 18a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v13a1 1 0 0 1-1 1z" clip-rule="evenodd" />
    //             </svg>

    //           </div>
    //           <p></p>
    //         </div>
    //       </div> */}


    //     </div>


    //   </div> 

    <div className='borders ' ref={innerRef}>
    <div id='print' className="font-times w-full bg-white shadow-md rounded-md grid grid-cols-1 place-content-center">
      <table className="w-full">
        <thead>
          <tr>
            <td>
              <div className="page-header font-times">
                <PrintHeader singleData={singleData} from={from} />
                <br />
              </div>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td>
              <PrintDetail singleData={singleData} from={from} supplierData={supplierData} taxDetails={taxDetails} taxTemplateId={taxTemplateId} />
            </td>
          </tr>
        </tbody>
  
        <tfoot>
          <tr>
            <td>
              <div className=" font-times page-footer">
                <PrintFooterOnly />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  
  );

}

