import React from 'react';
import moment from 'moment';
import { useState,useRef } from 'react';


const Table = ({ readOnly, productionDeliveryDetails ,date, phoneNo}) => {
  const componentRef = useRef(null);
  console.log(productionDeliveryDetails, "productionDeliveryDetails")
  const addMonths = (months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + parseInt(months, 10));
    return newDate;
};
const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
    
    <div >
        <table className="table-data border border-gray-500 text-xs table-auto w-full ">
          <thead className='border border-gray-500 top-0 bg-gray-200'>
            <tr className='border border-gray-500'>

            <th className="table-data w-2 text-center p-0.5">S.no</th>
                        <th className="table-data">Name Of the Bank<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Loan Type<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Loan Amount<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Total Tenure</th>
                        <th className="table-data">Closed Tenure</th>
                        <th className="table-data w-28">Balance Tenure<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-28">Emi Amount<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Loan Start Date<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Loan End Date<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Emi Status<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Phone No<span className="text-red-500 p-0.5">*</span></th>
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full bg-amber-200'>
            {productionDeliveryDetails.map((row, index) => (
              <tr key={index} className="w-full">
                <td className='table-data'>{index + 1}</td>
                <td className='table-data font-semibold'>
                  <div className='text-center'>{row.Bank.name}</div>
                </td>

                <td className='table-data p-2'>
                  {(!row.LoanType.name) ? "*" : (row.LoanType.name)}

                </td>
                <td className='table-data p-2'>{row.loanAmount}</td>
               <td className='table-data p-2'>{row.ttenure}</td> 
               <td className='table-data p-2'>{row.ctenure}</td> 
               <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={row.ttenure ? row.ttenure - row.ctenure : 0}
                                    disabled={readOnly}
                                />
                            </td>


                <td className='table-data p-2'>{row.emi}</td>
                <td className="table-data">
                                <input
                                    
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={row.lstart ? new Date(row.lstart).toISOString().split('T')[0] : ""}
                                    disabled={readOnly}
                                />
                            </td>

                            <td className="table-data">
                                <input
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={row.lend ? new Date(row.lend).toISOString().split('T')[0] : ""}
                                    disabled={true}
                                />
                            </td>
               <td className="table-data">
                                <span
                                    className={`text-right w-full rounded py-1 px-1 table-data-input ${row.lend <= today ? 'text-blue-500' : 'text-green-500'}`}
                                >
                                    {row.lend <= today ? 'Not Active' : 'Active'}
                                </span>
                            </td>
                            <td  className="table-data">
                              <span className='text-sm text-sky-800 font-bold'>{phoneNo}</span>
                            </td>
              
              </tr>
            ))}
           
          </tbody>
        </table>
        </div>
        </div>
      
    
  )
}

export default Table