import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  useGetProductServiceQuery,
} from '../../../redux/CrmServices/ProductServiceService';
import secureLocalStorage from "react-secure-storage";
import { FcDownload } from "react-icons/fc";

const PdfFormat = ({purData}) => {
  
  const tableRef = useRef(null);

  const downloadPDF = () => {
    const input = tableRef.current;
    html2canvas(input).then((canvas) => {
      console.log(canvas); 
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const today = new Date().toISOString().split("T")[0];

  const topThreeData = purData.slice(0, 10);

  return (
    <div className="max-w-full h-full mx-auto p-4  shadow-md rounded-lg">
      <div className="mb-6 border border-gray-300  bg-gray-100 p-4 rounded-lg overflow-x-auto">
        <table ref={tableRef} className="w-full bg-white table-auto">
          <thead>
            <tr className="bg-sky-800 text-white ">
              <th className="border px-4 py-2 font-thin">Name</th>
              <th className="border px-4 py-2 font-thin">Date</th>
              <th className="border px-4 py-2 font-thin">clientType</th>
              <th className="border px-4 py-2 font-thin">Paid Type</th>
              <th className="border px-4 py-2 font-thin">Product Brand</th>
              <th className="border px-4 py-2 font-thin">Product Category</th>
              <th className="border px-4 py-2 font-thin">ProductName</th>
              <th className="border px-4 py-2 font-thin">Qty</th>
              <th className="border px-4 py-2 font-thin">Price</th>
              <th className="border px-4 py-2 font-thin">tax</th>
              <th className="border px-4 py-2 font-thin">Total Price</th>


            </tr>
          </thead>
          <tbody>
            {topThreeData.map((item, index) => (
              <tr key={index} className="border">
                <td className="border px-4 py-2">{item.supplier.name}</td>
                <td className="border px-4 py-2">
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td className="border text-green-800 font-bold px-4 py-2">
                  {item.clientType}
                </td>
                <td className="border px-4 py-2">
                  {item.payType || "N/A"}
                </td>
              
                <td className="border px-4 py-2 bg-red-100">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>{detail.ProductBrand .name}</div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2 bg-green-100">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>{detail.ProductCategory.name}</div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2 bg-blue-100">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>{detail.Product.name}</div>
                      ))
                    : "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>
                          {detail.qty
                           }
                        </div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>
                          {detail.price
                           }
                        </div>
                      ))
                    : "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {item.ProductServiceItems && item.ProductServiceItems.length > 0
                    ? item.ProductServiceItems.map((detail, index) => (
                        <div key={index}>
                          {detail.tax
                           }
                        </div>
                      ))
                    : "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {item.netBillValue || "N/A"}
                </td>

              
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-end mt-4">
      <button
    className="border-2 bg-green-100 border-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    onClick={downloadPDF}
    title="Download"
  >
    <FcDownload />
  </button>

      </div>
    </div>
  );
};

export default PdfFormat;
