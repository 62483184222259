import React from 'react'
import secureLocalStorage from 'react-secure-storage';
import { useGetQuatationByIdQuery } from '../../../redux/CrmServices/Quatation';
import { useGetPartyQuery } from '../../../redux/CrmServices/PartyMasterService';
import PrintHeader from './PrintHeader';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
import PrintFooterOnly from './PrintFooter';
import PrintDetail from './PrintDetail';

require('number-to-text/converters/en-in');
const numberToText = require('number-to-text')

export default function PrintFormatSalesBill({ innerRef, id, taxTemplateId, poBillItems }) {
  console.log(poBillItems, "PoBillitems")

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }

  const { data: supplierList } =
    useGetPartyQuery({ params });
  const supplierData = supplierList?.data ? supplierList.data : []
  console.log(supplierData, "supplierData")

  const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetQuatationByIdQuery(id, { skip: !id });

  const from = {
    aliasName: "VISION GROUPS",
    name: "VISION GROUPS",
    address: "No 470 1 st floor opp h.p petrol bunk pn road pushpa Theader",
    near: " Near Richman hotel",
    pinCode: "641603",
    mobile: "India",
    gstNo: "GSTIN 33HAPPS7637H1ZZ",
    PlaceofSupply: "Tamilnadu(33)",
  }

  const fromAddress = {
    aliasName: "VISION GROUPS",
    name: "VISION GROUPS",
    address: "No 470 1 st floor opp h.p petrol bunk pn road near Richman hotel pushpa",
    gstNo: "Theader tirupur 641603",
    panNo: "641603",
    mobile: "India",
    gstNo: "GSTIN 33HAPPS7637H1ZZ",
    PlaceofSupply: "Tamilnadu(33)",
  }

  const { isLoading: isTaxHookDetailsLoading, ...taxDetails } = useTaxDetailsHook({
    poItems: (singleData?.data?.SalesBillItems || []).map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId, discountType: "percentage",
    discountValue: 0
  })
  if (!singleData) {
    return <div ref={innerRef}>No Data</div>;
  }
  function gstNo(supplierId, supplierData) {
    const matchingSupplier = supplierData.find(
      (supplier) => supplier.id === supplierId
    );

    return matchingSupplier || null;
  }
  console.log(singleData,"singleData")

  return (
    <div className='borders ' ref={innerRef}>
    <div id='print' className="font-times w-full bg-white shadow-md rounded-md grid grid-cols-1 place-content-center">
      <table className="w-full">
        <thead>
          <tr>
            <td>
              <div className="page-header font-times">
                <PrintHeader singleData={singleData} from={from} />
                <br />
              </div>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td>
              <PrintDetail singleData={singleData} from={from} supplierData={supplierData} taxDetails={taxDetails} taxTemplateId={taxTemplateId} />
            </td>
          </tr>
        </tbody>
  
        <tfoot>
          <tr>
            <td>
              <div className=" font-times page-footer">
                <PrintFooterOnly />
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  
  );

}

