import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { USERTYPE_DETAIL} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const userTypeDetailApi = createApi({
  reducerPath: "userTypeDetail",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["userTypeDetail"],
  endpoints: (builder) => ({
    getUserTypeDetailApi: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: USERTYPE_DETAIL +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: USERTYPE_DETAIL,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["userTypeDetail"],
    }),
    addUserTypeDetail: builder.mutation({
      query: (payload) => ({
        url: USERTYPE_DETAIL,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["userTypeDetail"],
    }),
    updateUserTypeDetail: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${USERTYPE_DETAIL}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["userTypeDetail"],
    }),
    updateManyBranch: builder.mutation({
      query: (payload) => {
        const { companyId, branches } = payload;
        return {
          url: `${USERTYPE_DETAIL}/updateMany/${companyId}`,
          method: "PUT",
          body: branches,
        };
      },
      invalidatesTags: ["Branch"],
    }),
    deleteUserTypeDetail: builder.mutation({
      query: (id) => ({
        url: `${USERTYPE_DETAIL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["userTypeDetail"],
    }),
  }),
});

export const {
  useGetUserTypeDetailApiQuery,
  useAddUserTypeDetailMutation,
  useUpdateUserTypeDetailMutation,
  useDeleteUserTypeDetailMutation,
  useUpdateManyBranchMutation
} = userTypeDetailApi;

export default userTypeDetailApi;
