import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { USER_TYPE } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const UserTypeApi = createApi({
  reducerPath: "UserType",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["UserType"],
  endpoints: (builder) => ({
    getUserType: builder.query({
      query: () => {
        
        return {
          url: USER_TYPE,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
     
        };
      },
      providesTags: ["UserType"],
    })
})
})

export const { useGetUserTypeQuery } = UserTypeApi;

export default UserTypeApi;
