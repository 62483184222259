import React, { useState, useRef } from 'react';
import moment from 'moment';

const Table = ({ readOnly, productionDeliveryDetails, date, netBillValue }) => {
  const componentRef = useRef(null);
  console.log(productionDeliveryDetails, "productionDeliveryDetails");

  const addMonths = (months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + parseInt(months, 10));
    return newDate;
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
 console.log(netBillValue,"prod")
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="p-4 bg-gray-100">
      <div className="overflow-x-auto shadow-lg rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-6 text-center font-thin">S.no</th>
              <th className="py-3 px-6 text-left font-thin">Product Brand<span className="text-red-500">*</span></th>
              <th className="py-3 px-6 text-left font-thin">Product Category<span className="text-red-500">*</span></th>
              <th className="py-3 px-6 text-left font-thin">Product Name<span className="text-red-500">*</span></th>
              <th className="py-3 px-6 text-right font-thin">Qty</th>
              <th className="py-3 px-6 text-right font-thin">Price</th>
              <th className="py-3 px-6 text-right font-thin">Tax</th>
              <th className="py-3 px-6 text-right  font-thin">Total Price</th>

            </tr>
          </thead>
          <tbody className="bg-gray-100 text-gray-800">
            {productionDeliveryDetails.map((row, index) => (
              <tr key={index} className="hover:bg-gray-200 transition duration-150 ease-in-out">
                <td className="py-3 px-6 text-center border-b">{index + 1}</td>
                <td className="py-3 px-6 text-left border-b">{row.ProductBrand.name}</td>
                <td className="py-3 px-6 text-left border-b">{row.ProductCategory.name}</td>
                <td className="py-3 px-6 text-left border-b">{row.Product.name}</td>
                <td className="py-3 px-6 text-right border-b">{row.qty}</td>
                <td className="py-3 px-6 text-right border-b">{row.price}</td>
                <td className="py-3 px-6 text-right border-b">{row.tax}</td>
                <td className="py-3 px-6 text-right border-b">{netBillValue}</td>

              </tr>
            ))}
          </tbody>
        </table>  
      </div>
    </div>
  );
};

export default Table;
