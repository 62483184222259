import React, { useEffect, useState } from 'react';
import { useGetProductByIdQuery } from '../../../redux/CrmServices/ProductMasterService';
import { Link } from 'react-router-dom';

function InputDialog({ poBillItems, productId, setPoBillItems, indexs, readOnly,returnData,setReturnData }) {
    const [clickedInputs, setClickedInputs] = useState(Array(poBillItems[indexs]["simDetails"].length).fill(false));
    const { data: singleData } = useGetProductByIdQuery(productId, { skip: !productId });
    console.log(clickedInputs,"clickedInputs")
    const handleInputChange = (i, field, e) => {
        const updateInputData = structuredClone(poBillItems);
        updateInputData[indexs]["simDetails"][i][field] = e.target.value;
        setPoBillItems(updateInputData);
    };
   const handleInputClick = (i) => {
    const updatedClickedInputs = [...clickedInputs];
    updatedClickedInputs[i] = !updatedClickedInputs[i]; 
    setClickedInputs(updatedClickedInputs);
    
    let updatedReturnData; 
    if (!clickedInputs[i]) {
        updatedReturnData = [...returnData, poBillItems[indexs]["simDetails"][i]];
    } else {
        const findData = poBillItems[indexs]["simDetails"][i];
        updatedReturnData = returnData.filter(item => item.imei !== findData.imei);
    }
    setReturnData(updatedReturnData);
};

useEffect(() => {
    setPoBillItems(prev => {
        let newPrev = structuredClone(prev);
        console.log( newPrev[indexs],"new indesxs")
        newPrev[indexs]["returnData"] = returnData;
        return newPrev;
    });
}, [returnData]);
const handleClose = () => {
    setReturnData([]);
    setClickedInputs(prev=>{
        let newSelect = structuredClone(prev)
        return newSelect
    }); 
    console.log(clickedInputs   ,"setClickedInputs")
    setPoBillItems(prev => {
        let newPrev = structuredClone(prev);
        newPrev[indexs]["isOpen"] = false;
        return newPrev;
    });
};


console.log(poBillItems,"poBillItems")
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-amber-50 rounded-lg shadow-lg p-6 ">
                <h2 className="text-lg font-semibold text-blue-700 mb-4">ENTER IMEI DETAIL</h2>
                <table className="w-full">
                    <tbody className="space-y-4">
                        {(poBillItems[indexs]["simDetails"] || []).map((value, i) =>

                            <tr key={i}>
                                {singleData?.data?.iactive && (
                                    <>
                                        <td className='pr-2'>
                                            <label htmlFor={`imei-input-${i}`}>IMEI No {i + 1}</label>
                                        </td>
                                        <td>
                                            <input
                                                style={{ backgroundColor: clickedInputs[i] ? '#6ee7b7' : '#fef3c7' }}
                                                className="p-1 border border-gray-500 rounded"
                                                id={`imei-input-${i}`}
                                                disabled={readOnly}
                                                type="text"
                                                value={value?.imei ? value.imei : ''}
                                                onChange={(e) => handleInputChange(i, 'imei', e)}
                                                onClick={() => handleInputClick(i)}
                                            />
                                        </td>
                                    </>
                                )}
                                {singleData?.data?.sactive && (
                                    <>
                                        <td className='pr-2'>
                                            <label htmlFor={`sim-input-${i}`}>SIM No {i + 1}</label>
                                        </td>
                                        <td>
                                            <input
                                                style={{ backgroundColor: clickedInputs[i] ? '#6ee7b7' : '#fef3c7' }}
                                                className="p-1 border border-gray-500 rounded"
                                                id={`sim-input-${i}`}
                                                type="text"
                                                value={value?.sim}
                                                onChange={(e) => handleInputChange(i, 'sim', e)}
                                                onClick={() => handleInputClick(i)}
                                            />
                                        </td>
                                    </>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className='text-center pt-2'>
                    <Link to="#_" className="relative inline-block text-lg group">
                        <span onClick={handleClose} className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-green-800 transition-colors duration-300 ease-out border-2 border-green-900 rounded-lg group-hover:text-white">
                            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-green-50"></span>
                            <span className="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-green-900 group-hover:-rotate-180 ease"></span>
                            <span className="relative">Enter</span>
                        </span>
                        <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-green-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
                    </Link>
                </div>
            </div>
            <button
                onClick={handleClose}
                className="absolute top-2 right-2 bg-red-500 rounded-xl text-white hover:text-black"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

        </div>
    );
}

export default InputDialog;
