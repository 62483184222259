import React, { useEffect, useState } from 'react';
import { useGetProductQuery } from '../../../redux/CrmServices/ProductMasterService';
import { useGetProductCategoryQuery } from '../../../redux/CrmServices/ProductCategoryServices';
import { useGetProductBrandQuery } from '../../../redux/CrmServices/ProductBrandService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { add } from 'lodash';
import moment from 'moment';
import StockQty from './StockQty';
import { DELETE } from '../../../icons';
import { PLUS } from '../../../icons';
import { toast } from "react-toastify";
import Imei from './Imei';


const PoBillItems = ({ id, readOnly, setPoBillItems, poBillItems, isTaxBill, date,serviceType}) => {
    const [index,setIndex]= useState("")
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } =
        useGetProductBrandQuery({ params });

    const { data: productCategoryList } =
        useGetProductCategoryQuery({ params });

    const { data: productList } = useGetProductQuery({ params });

    function handleInputChange(value, index, field) {


        const newBlend = structuredClone(poBillItems);
        newBlend[index][field] = value;

        setPoBillItems(newBlend);

    };
    function deleteRow(index) {
        setPoBillItems(prev => prev.filter((_, i) => i !== index))
    }
    function addNewRow() {
        setPoBillItems(prev => [

            { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000", salePrice: '0.00' }, ...prev
        ]);
    }

    useEffect(() => {
        if (poBillItems.length >= 10) return
        setPoBillItems(prev => {
            let newArray = Array.from({ length: 10 - prev.length }, i => {
                return { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000" }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setPoBillItems])
    function getTotal(field1, field2, field3) {
        const total = poBillItems.reduce((accumulator, current) => {

            return accumulator + parseFloat(current[field1] && current[field2] && current[field3] ? (current[field1] * current[field2]) + ((current[field1] * current[field2]) * current[field3]) / 100 : 0)
        }, 0)
        return parseFloat(total)
    }
    function getTotal1(field1, field2) {
        const total = poBillItems.reduce((accumulator, current) => {

            return accumulator + parseFloat(current[field1] && current[field2] ? (current[field1] * current[field2]) : 0)
        }, 0)
        return parseFloat(total)
    }
    const addMonths = (months) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + parseInt(months, 10));
        return newDate;
    };


    if (!productBrandList || !productCategoryList || !productList) return <Loader />

    return (
        <>

            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className=" border border-gray-500 text-xs table-auto  w-full">
                    <thead className='bg-sky-700  top-0 border-b border-gray-500 text-white tracking-widest font-thin font-sans'>
                        <tr className='p-2'>
                            <th className="table-data  w-2 text-center p-0.5">S.no</th>
                            <th className="table-data ">Product Brand<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data ">Product Category<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data ">Product Name<span className="text-red-500 p-5">*</span></th>                            <th className="table-data ">Vehicle No<span className="text-red-500 p-1">*</span></th>
                            <th className="table-data ">Imei Detail</th>
                        
                                <th className="table-data">Months <span className="text-red-500 p-2"></span></th>
                                <th className="table-data">RenewalDate <span className="text-red-500 p-2"></span></th>
                          
                            <th className="table-data  w-28">StockQty<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data  w-28">Qty<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data  w-32">Price<span className="text-red-500 p-0.5">*</span></th>
                            {isTaxBill ? <th className="table-data w-24 p-0.5">Tax</th> : null}
                            {isTaxBill ? <th className="table-data w-24 p-0.5">Tax Rate</th> : null}
                            <th className="table-data  w-24 p-0.5">Tax Amount</th>
                            <th className="table-data  w-10 p-0.5 bg-white text-sky-800 text-lg">  <button onClick={addNewRow}>{PLUS}</button></th>


                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto h-full w-full'>


                        {(poBillItems ? poBillItems : []).map((item, index) =>

                            <tr key={index} className="w-full table-row">
                                <td className="table-data w-2 text-left px-1 py-1">
                                    {index + 1}
                                </td>


                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productBrandId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productBrandId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productBrandId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productBrandId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productBrandList.data : productBrandList.data.filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productCategoryId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productCategoryId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productCategoryId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productCategoryId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productCategoryList.data : productCategoryList.data.filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productList.data : productList.data.filter(value => parseInt(value.productBrandId) === parseInt(item.productBrandId) && parseInt(value.productCategoryId) === parseInt(item.productCategoryId)).filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        className="text-right w-full rounded py-1 px-1  table-data-input"

                                        value={(!item.vehicleNo) ? "" : item.vehicleNo}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "vehicleNo")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(e.target.value, index, "vehicleNo");

                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                        <div style={{ position: 'relative' }}>

                                            <span
                                                className=' pl-2 cursor-pointer text-center '
                                                style={{
                                                    backgroundImage: 'linear-gradient(to right, red, blue)',
                                                    WebkitBackgroundClip: 'text',
                                                    color: 'transparent',
                                                    zIndex: '1',

                                                }}
                                                onClick={() => {
                                                    handleInputChange(true, index, "isOpen")
                                                    setIndex(index);
                                                }}
                                            >
                                                Click Here
                                            </span>
                                        </div>

                                        {item?.isOpen && <Imei key={item?.isOpen} productId={item.productId} indexs = {index}  
                                            poBillItems={poBillItems} setPoBillItems={setPoBillItems}  readOnly={readOnly} id ={id} serviceType = {serviceType} />}
                                    </td>
                               
                              
                                        <td className='table-data'>
                                            <input
                                                type="text"
                                                className="text-right w-full rounded py-1 px-1  table-data-input"

                                                value={(item?.validationMonths) ? item.validationMonths : 0}
                                                disabled={readOnly}
                                                onChange={(e) =>
                                                    handleInputChange(e.target.value, index, "validationMonths")
                                                }
                                            />
                                        </td>
                                        <td className='table-data'>
                                            {moment(addMonths(item?.validationMonths ? item?.validationMonths : 0)).format("DD-MM-YYYY")}
                                        </td>
                                  
                              <StockQty 
                              productId={item.productId}
                              readOnly={readOnly}
                              index = {index}
                              setPoBillItems = {setPoBillItems}
                              />

                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className={`text-right rounded py-1 px-1 w-16 table-data-input ${
                                            !item.qty || (item.qty > parseInt(item.stockQty))
                                              ? toast.info("Please fill Below StockQty...!", { position: "top-center" })
                                              : ""
                                          }`}
                                        value={(!item.qty) ? 0 : item.qty}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "qty");

                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data'>

                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1  table-data-input"

                                        value={item.price ? item.price : 0.000}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "price");

                                        }
                                        }
                                    />
                                </td>
                                {isTaxBill ? (<td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 table-data-input"
                                        value={(item.tax !== 0) ? item.tax : 0}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value ? (e.target.value) : 0, index, "tax")}

                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value), index, "tax");
                                        }}
                                    />
                                </td>) : null}

                                {isTaxBill ? (<td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1  table-data-input"

                                        value={((parseFloat((parseFloat(item.qty) * parseFloat(item.price)).toFixed(2)) * parseFloat(item.tax)) / 100).toFixed(2)}
                                        disabled={readOnly}

                                    />
                                </td>) : null}

                                {isTaxBill ? (<td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1  table-data-input"

                                        value={(parseFloat((parseFloat(item.qty) * parseFloat(item.price)).toFixed(2)) + (parseFloat((parseFloat(item.qty) * parseFloat(item.price))) * parseFloat(item.tax)) / 100).toFixed(2)}
                                        disabled={readOnly}

                                    />
                                </td>
                                ) : <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1  table-data-input"

                                        value={parseFloat((parseFloat(item.qty) * parseFloat(item.price)).toFixed(2))}
                                        disabled={readOnly}

                                    />
                                </td>}
                                <td className="border border-gray-500 text-xs text-center">
                                    <button
                                        type='button'
                                        onClick={() => {
                                            deleteRow(index)
                                        }}
                                        className='text-xs text-red-600 '>{DELETE}
                                    </button>
                                </td>
                                </tr>
                        )}
                        <tr className='bg-gray-300 w-full border border-gray-400 h-7 font-bold'>
                            <td className="table-data text-center w-10 font-bold" colSpan={isTaxBill ? (poBillItems.some(item => item?.returnData) ? 13 : 13) : (poBillItems.some(item => item?.returnData) ? 11 : 11)}>
                                Total
                            </td>

                            <td className="table-data w-10 text-right pr-1">
                                {isTaxBill ? getTotal("qty", "price", "tax").toFixed(2) : getTotal1("qty", "price").toFixed(2)}
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PoBillItems