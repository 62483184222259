import React, { useState, useRef } from "react";
import secureLocalStorage from "react-secure-storage";

import {
  useGetLeadImportFormQuery,
  useGetLeadImportFormByIdQuery,
  useUpdateLeadImportFormMutation,
  useDeleteLeadImportFormMutation,
  useImportLeadMutation,
} from "../../../redux/CrmServices/LeadImportFormServices";
import { useGetLeadCategoriesMasterQuery } from '../../../redux/CrmServices/LeadCategoriesMasterServices'

import { dropDownListObject } from "../../../Utils/contructObject";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { DateInput, DropdownInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import ImportMappingPopUp from './ImportMappingPopUp';

import ExcelSelectionTable from "./ExcelSelectionTable";
import moment from "moment";
import { DOWNLOAD } from "../../../icons";
import Modal from "../../../UiComponents/Modal";
import ReactLoading from 'react-loading';
import { useGetUserTypeQuery } from "../../../redux/CrmServices/UserType"
import { useDispatch } from "react-redux";

const MODEL = "Data Import Form";
export default function Form() {
  const dispatch = useDispatch()
  const today = new Date()

  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [formReport, setFormReport] = useState(false);
  const [itemsPopup, setItemsPopup] = useState(false)
  const [leadCategoriesId, setLeadCategoriesId] = useState("")
  const [excelFields, setExcelFields] = useState([])
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
console.log(selectedValue,"selectedValue")

  const Headerrow = (header) => {
    setExcelFields(header);
  }
  const { data: userType } = useGetUserTypeQuery();

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };

  const { data: allData, isLoading, isFetching } = useGetLeadImportFormQuery({ params, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetLeadImportFormByIdQuery(id, { skip: !id });

  const [addData] = useImportLeadMutation();
  const [updateData] = useUpdateLeadImportFormMutation();
  const [removeData] = useDeleteLeadImportFormMutation();


  // const syncFormWithDb = useCallback((data) => {
  //   if (id) {
  //     setReadOnly(true);
  //   } else {
  //     setReadOnly(false);
  //   }
  //   setLeadCategoriesId(data?.leadCategoriesId ? data?.leadCategoriesId : "");
  //   if (data?.date) setDate(data?.date);
  // }, [id]);


  // useEffect(() => {
  //   if (id) {
  //     syncFormWithDb(singleData?.data);
  //   } else {
  //     syncFormWithDb(undefined);
  //   }
  // }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const { data: LeadCategoriesList } =
    useGetLeadCategoriesMasterQuery({ params: { ...params, active: true } });

  const data = {
    leadCategoriesId,selectedValue
  }

  const validateData = (data) => {
    return true
  }

  // const Rows = ([rows]) => {
  //   setRow(rows);
  // }
  const handleSubmitCustom = async (callback, data, text) => {
    try {
      setLoading(true);

      let returnData;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      formData.append("excelFields", JSON.stringify(excelFields));
      if (file instanceof File) {
        formData.append("file", file);
      }
      if (text === "Updated") {
        returnData = await callback({ body: formData }).unwrap();
      } else {
        returnData = await callback(formData).unwrap();
      }
      setId(returnData.data);
      toast.success(text + " Successfully");
      dispatch({
        type: `AllocationForm/invalidateTags`,
        payload: ['AllocationForm'],
      });
    } catch (error) {
      console.error("Error:", error);
      console.log("Data:", data);
      console.log("Text:", text);
      console.log("File:", file);
    }
    finally {
      setLoading(false);
    }
  };
  const handleSelectType = (value) => {
    if (value === 'selectAll') {
      const allValues = userType?.data?.map((item) => item.id) || [];
      setSelectedValue('selectAll');
    } else {
      setSelectedValue((prevSelectedValue) => {
        return prevSelectedValue === value ? '' : value;
      });
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (!leadCategoriesId) {
      alert('Please select Lead Out Source');
      return;
    }
    if(!selectedValue){
      alert("please select a UserType")
      return;
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };


  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }
  const tableHeaders = ["Source", "Date"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const openModel = () => {
    setItemsPopup(true)
  }

  if (!form)
    return (<>
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLoading || isFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </>

    );

  return (
    <div>
      {loading ? <div className="spinner-container">
        <ReactLoading type={'spin'} color={'#00BFFF'} height={50} width={50} />
      </div> : null}

      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >

        <Modal isOpen={itemsPopup} onClose={() => {
          setItemsPopup(false);
        }} widthClass={"w-[1000px] h-[500px] overflow-auto"}>
          <>
            <ImportMappingPopUp excelFields={excelFields} setExcelFields={setExcelFields} leadCategoriesId={leadCategoriesId} importedData={excelFields} header={excelFields} />
          </>
        </Modal>
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            onClose={() => {
              setForm(false);
              setSearchValue("");
            }}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                    <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 h-[20%] px-3 overflow-auto'>
                        <legend className='sub-heading'>Lead Details</legend>
                        <div className="flex justify-end relative top-0 right-0">
                          <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                            {formReport ? "Close Report >" : "Open Report <"}
                          </button>
                        </div>
                        <div className='flex flex-col justify-center items-start flex-1 w-full'>
                          <div className="flex flex-wrap ">
                            <div className="flex justify-center gap-5">
                              <select
                                color="blue"
                                label="Select Version"
                                className="bg-gray-200 border-2 border-gray-400 text-gray-900 text-sm rounded-lg mx-10 my-2 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500  dark:focus:border-blue-500"
                                onChange={(e) => handleSelectType(parseInt(e.target.value))}
                                value={selectedValue}
                              >
                                <option value="" disabled hidden>
                                  Select a Type
                                </option>
                                {userType?.data?.map((value) => (
                                  <option key={value.id} value={value.id}>
                                    {value.name}
                                  </option>
                                ))}
                              </select>
                              <DropdownInput name="Lead Out Source" type={"date"} required={true} readOnly={readOnly} value={leadCategoriesId} setValue={setLeadCategoriesId} options={dropDownListObject(LeadCategoriesList ? LeadCategoriesList.data : [], "name", "id")} />
                              <DateInput name="Lead Imported Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                              <button className="bg-blue-500 text-white rounded p-1 hover:bg-blue-300 transition" onClick={openModel} setValue={setLeadCategoriesId}>Import {DOWNLOAD}</button>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex h-[400px] px-3 overflow-auto'>
                        <legend className='sub-heading'>Lead Import Details</legend>
                        <ExcelSelectionTable file={file} setFile={setFile} params={params} readOnly={readOnly} Headerrow={Headerrow} />
                      </fieldset>
                    </div>
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data}
                        loading={
                          isLoading || isFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}