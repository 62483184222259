import React from 'react';
import ProductionDeliveryDetails from './ProductionDeliveryDetails';
import TaxDetails from './TaxDetails';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
require('number-to-text/converters/en-in');
const numberToText = require('number-to-text');

const PrintDetail = ({ singleData, from, supplierData, taxDetails, taxTemplateId }) => {
  const gstNo = (supplierId) => {
    const matchingSupplier = supplierData.find(
      (supplier) => supplier.id === supplierId
    );

    return matchingSupplier || null;
  };
  const features = [
    "ONE YEAR WARRANTY",
    "REAL TIME TRACKING",
    "PACKED BY 24 HOURS",
    "PARKING ALERTS",
    "GPS HISTORY",
    "VALUABLE SCREEN INFO",
    "SCREEN LOCATION ON DEMAND",
    "ZONE ALERTS",
    "UNPLUGGED DEVICE",
    "DAILY REPORT BY MAIL",
    "TRIP REPORTS ONLINE ENGINE",
    "ON/OFF FACILITIES",
    "EASY ACCESS FROM ANYWHERE IN THE WORLD"
  ];

  return (
    <>
      <div className='grid grid-cols-2 bg-sky-800 text-white border-t p-1 border-gray-500 pl-3'>
        <div>VENDOR:</div>
        <div className='text-center'>DELIVERY TO:</div>
      </div>

      <div className='grid grid-cols-3 bg-gray-100 border-gray-500'>
        <div className='text-start p-1 w-full'>
          <div className='w-full text-center p-1'>
            <h3 className='font-bold text-green-700 text-lg'>{from.name}</h3>
            <h3 className='text-xs'>{from.address}</h3>
            <h3 className='text-xs'>{from.near}</h3>
            <h3 className='text-xs'>{from.pinCode}</h3>
            <h3 className='text-xs'>GSTNO: {from.gstNo}</h3>
            <h3 className='text-xs'>Place of Supply: {from.PlaceofSupply}</h3>
          </div>
        </div>
        <div className='flex bg-gray-100 border-l border-t border-gray-500'>
          <div className='p-3 flex-1'>
            <div className='grid grid-cols-2 gap-2'>
              <div className='text-left pl-3 bg-green-500 text-white rounded-xl p-1 text-xs'>PO NO:</div>
              <div className='text-right pr-3 bg-green-500 text-white rounded-xl p-1 text-xs'>{singleData?.data?.docId}</div>
              <div className='text-left pl-3 text-xs'>Delivery Date:</div>
              <div className='text-right pr-3 text-xs'>{getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</div>
              <div className='text-left pl-3 text-xs'>Customer:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.name : '-'}</div>
              <div className='text-left pl-3 text-xs'>GST Number:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.gstNo : '-'}</div>
            </div>
          </div>
        </div>
        <div className='flex bg-gray-100 border-l border-r border-t border-gray-500'>
          <div className='p-2 flex-1 text-justify'>
            <div className='grid grid-cols-2 gap-1'>
              <div className='text-xs'>Contact Person:</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactPersonName : '-'}</div>
              <div className='text-xs'>Contact:</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactMobile : '-'}</div>
              <div className='text-xs'>Address</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.address : '-'}</div>
              <div className='text-xs'>City</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.City?.name : '-'}</div>
              <div className='text-xs'>Pincode</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.pincode : '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ProductionDeliveryDetails productionDeliveryDetails={singleData?.data?.QuatationItems || []} date={singleData?.data?.createdAt || []} />
      </div>

      <div className='flex justify-between p-1'>
    
        {singleData?.data?.SalesBillItems && singleData.data.SalesBillItems.some(item => item.tax > 0) && (
          <table className='border-l border-t border-gray-500 text-center border-spacing-2 border border-slate-400 w-56 bg-white rounded-lg shadow'>
            <tbody>
              <tr className='border border-gray-500'>
                <th className='p-1 bg-green-500 text-xs text-white' colSpan={2}>Tax Details</th>
              </tr>
              <TaxDetails
                items={(singleData?.data?.SalesBillItems || []).map(i => ({ ...i, taxPercent: i.tax }))}
                taxTemplateId={taxTemplateId}
                productionDeliveryDetails={singleData?.data?.SalesBillItems || []}
                discountType="percentage"
                discountValue={0}
              />
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1'>TAX RATE</td>
                <td className='table-data p-1'>{(parseFloat(taxDetails.cgstAmount) + parseFloat(taxDetails.sgstAmount)).toFixed(2)}</td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1'>ROUND OFF</td>
                <td className='table-data p-1'>{parseFloat(taxDetails?.roundOffAmount).toFixed(2)}</td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-500 text-xs text-white'>NET AMOUNT</td>
                <td className='table-data p-1 text-xs bg-green-500 text-white'>{parseFloat(taxDetails?.netAmount).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div className='w-full'>
        <div className='w-full flex items-center bg-sky-800 p-2 text-white'>
          <h1 className='text-xs pl-3 tracking-wider uppercase'>Amount in words:</h1>
          <span className='text-xs uppercase'>
            {numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
          </span>
        </div>
      </div>

      <div className='bg-green-100 pl-2 rounded-lg shadow'>
      <h2 className='font-bold text-lg text-green-700'>GPS Tracker Features</h2>
      <div className='grid grid-cols-3 text-xs'>
        {features.map((feature, index) => (
          <div key={index} className='relative pl-6'>
            <span className='absolute left-0 text-green-700'>➜</span>
            {feature}
          </div>
        ))}
      </div>
    </div>

      <div className='p-4 bg-green-100  rounded-lg shadow mt-4'>
        <h2 className='font-bold text-lg text-green-700'>Terms and Conditions</h2>
        <div className='grid grid-cols-2 pl-2 text-xs'>
          <div>1. ONE YEAR WARRANTY</div>
          <div>2. ONE YEAR SUBSCRIPTION INCLUDED</div>
          <div>3. IF THE VEHICLES INCREASE OR DECREASE, THE PRICE WILL BE CHANGED</div>
          <div>4. THIS QUOTED PRICE IS VALID ONLY FOR SEVEN DAYS</div>
        </div>
      </div>
    </>
  );
};

export default PrintDetail;
