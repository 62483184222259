import { configureStore } from "@reduxjs/toolkit";
import { openTabs } from "./features";
import {
  countryMasterApi, pageMasterApi, stateMasterApi,
  cityMasterApi, departmentMasterApi, employeeCategoryMasterApi,
  finYearMasterApi, rolesMasterApi, employeeMasterApi, userMasterApi, 
  branchMasterApi, companyMasterApi, pageGroupMasterApi,TaxTermMasterApi, TaxTemplateApi,
} from "./services"

import { LeadCategoriesMasterApi,productBrandMasterApi, LeadImportFormApi,productMasterApi,HeadingCategoriesMasterApi,AllocationFormApi,UserFormApi,CallLogApi, UserTypeApi,UserTypeDetailApi,purchaseBillApi,purchaseBillsApi, productBrandApi,purchaseInwardApi,
  productCategoryApi,stockApi,
  productApi, uomApi,
  purchaseReturnApi, productCategoryMasterApi, uomMasterApi,partyMasterApi, partyCategoryMasterApi,salesBillApi ,buyerMasterApi,
  simimeiApi,salesReturnApi,bankMasterApi,loanTypeMasterApi,loanTenureApi,serviceDetMasterApi,quatationApi,ProductServiceApi
 } from './CrmServices'


const commonReducers = {
  openTabs,
  countryMaster: countryMasterApi.reducer,
  pageMaster: pageMasterApi.reducer,
  stateMaster: stateMasterApi.reducer,
  cityMaster: cityMasterApi.reducer,
  departmentMaster: departmentMasterApi.reducer,
  employeeCategoryMaster: employeeCategoryMasterApi.reducer,
  finYearMaster: finYearMasterApi.reducer,
  roleMaster: rolesMasterApi.reducer,
  userMaster: userMasterApi.reducer,
  employeeMaster: employeeMasterApi.reducer,
  branchMaster: branchMasterApi.reducer,
  companyMaster: companyMasterApi.reducer,
  pageGroupMaster: pageGroupMasterApi.reducer,
  taxTermMaster: TaxTermMasterApi.reducer,
  taxTemplate: TaxTemplateApi.reducer,
}
const commonMiddleware = [countryMasterApi.middleware,
pageMasterApi.middleware,
stateMasterApi.middleware,
cityMasterApi.middleware,
departmentMasterApi.middleware,
employeeCategoryMasterApi.middleware,
finYearMasterApi.middleware,
rolesMasterApi.middleware,
userMasterApi.middleware,
employeeMasterApi.middleware,
branchMasterApi.middleware,
companyMasterApi.middleware,
pageGroupMasterApi.middleware,
TaxTermMasterApi.middleware,
TaxTemplateApi.middleware,
];

const crmReducers = {
  leadCategoriesMaster: LeadCategoriesMasterApi.reducer,
  leadImportForm: LeadImportFormApi.reducer,
  AllocationForm: AllocationFormApi.reducer,
  UserForm: UserFormApi.reducer,
  CallLog: CallLogApi.reducer,
  [UserTypeApi.reducerPath]: UserTypeApi.reducer,
  [UserTypeDetailApi.reducerPath]: UserTypeDetailApi.reducer,
  [HeadingCategoriesMasterApi.reducerPath]: HeadingCategoriesMasterApi.reducer,
  [purchaseBillsApi.reducerPath]: purchaseBillsApi.reducer,
  [loanTenureApi.reducerPath]: loanTenureApi.reducer,
  purchaseInward: purchaseInwardApi.reducer,
  productCategory: productCategoryApi.reducer,
  product: productApi.reducer,
  uom: uomApi.reducer,
  purchaseReturn: purchaseReturnApi.reducer,
  [salesReturnApi.reducerPath]: salesReturnApi.reducer,
  [productBrandMasterApi.reducerPath]: productBrandMasterApi.reducer,
  [serviceDetMasterApi.reducerPath ]: serviceDetMasterApi.reducer,
  [bankMasterApi.reducerPath]:bankMasterApi.reducer,
  [loanTypeMasterApi.reducerPath]:loanTypeMasterApi.reducer,
  [quatationApi.reducerPath] : quatationApi.reducer,
  [ ProductServiceApi.reducerPath ] : ProductServiceApi.reducer,
  productMaster: productMasterApi.reducer,
  productCategoryMaster: productCategoryMasterApi.reducer,
  uomMaster: uomMasterApi.reducer,
  purchaseBill: purchaseBillApi.reducer,
  partyMaster: partyMasterApi.reducer,
[buyerMasterApi.reducerPath]:buyerMasterApi.reducer,
  partyCategoryMaster: partyCategoryMasterApi.reducer,
  stock: stockApi.reducer,
  salesBill: salesBillApi.reducer,
  simimei:simimeiApi.reducer,
  
}

const crmMiddleware = [
  LeadCategoriesMasterApi.middleware,
  LeadImportFormApi.middleware,
  HeadingCategoriesMasterApi.middleware,
  AllocationFormApi.middleware,
  UserFormApi.middleware,
  CallLogApi.middleware,
  ProductServiceApi.middleware,
  UserTypeApi.middleware,
  UserTypeDetailApi.middleware,
  purchaseBillsApi.middleware,
  purchaseBillApi.middleware,
  purchaseInwardApi.middleware,
  productBrandApi.middleware,
  bankMasterApi.middleware,
  loanTypeMasterApi.middleware,
  productCategoryApi.middleware,
  productApi.middleware,
  uomApi.middleware,
  purchaseReturnApi.middleware,
  salesReturnApi.middleware,
  productBrandMasterApi.middleware,
  productMasterApi.middleware,
  productCategoryMasterApi.middleware,
  uomMasterApi.middleware,
  partyMasterApi.middleware,
  buyerMasterApi.middleware,
  quatationApi.middleware,
  partyCategoryMasterApi.middleware,
  stockApi.middleware,
  salesBillApi.middleware,
  simimeiApi.middleware,
  loanTenureApi.middleware,
  serviceDetMasterApi.middleware
]

export const store = configureStore({
  reducer: {
    ...commonReducers,...crmReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(commonMiddleware).concat(crmMiddleware),
});

export default store;