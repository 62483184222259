import React, { useState, useEffect } from 'react';
import { useGetHeadingCategoriesMasterQuery } from '../../../redux/CrmServices/HeadingCategoriesMasterServices';
import { useGetCallLogQuery } from '../../../redux/CrmServices/CallLog';
import { useGetAllocationFormByIdQuery, } from '../../../redux/CrmServices/AllocationForm';
import secureLocalStorage from "react-secure-storage";
import { FcEndCall, FcCallback } from "react-icons/fc";
import { FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAddCalllogMutation } from '../../../redux/CrmServices/CallLog';
import { RiUserUnfollowFill, RiUserFollowFill } from "react-icons/ri"
import { FcLeave } from "react-icons/fc";
import { useDispatch } from 'react-redux';
import { useGetUserTypeQuery } from "../../../redux/CrmServices/UserType"
import { FcRefresh } from "react-icons/fc"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Loader } from '../../../Basic/components';
import { MdHistory } from "react-icons/md";
import { useGetUserFormQuery } from '../../../redux/CrmServices/UserForm';
import { PhoneIcon } from '@heroicons/react/outline';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';
import { FcViewDetails } from "react-icons/fc";
const CallLog = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const userIdFromStorage = secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userId"
    );
    setUserId(userIdFromStorage);
  }, []);
  const params = { userId };
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]
});  const [selectedData, setSelectedData] = useState(null);
  const [setId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [rowStates, setRowStates] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [storeData, setStoreData] = useState({});
  const [addData] = useAddCalllogMutation();
  const [allocatedDataId, setAllocatedDataId] = useState(null);
  const [selectLeed, setSelectedLeed] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectType,setSelectType] = useState('')
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { data: userType } = useGetUserTypeQuery();
  const { data: headingData } = useGetHeadingCategoriesMasterQuery({});
  const { data: allData, isLoading, isFetching, refetch } = useGetCallLogQuery({ params: { ...params, selectedValue,selectType, filterType: selectLeed,user:selectedUser, startDate } });
  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };
    const [recordCount, setRecordCount] = useState(0);
  
    const handleDateChange1 = (event) => {
      setStartDate(event.target.value);
  };
  
  console.log(selectedUser,"selectUser")
  useEffect(() => {
    setStoreData({
      commentValue,
      selectedDate,
      selectedUser,
      allocatedDataId,
      selectedStatus,
      selectType, userId
    });
  }, [commentValue, selectedDate, allocatedDataId, selectedStatus,selectType])

  const { data: user } = useGetUserFormQuery({
    params: { key: selectedValue }
  })
  const { data: userData } = useGetUserByIdQuery({
    params: { key: userId }
  })
  console.log(userData,"userData")

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();
      // setId(returnData.callLogData.id);
      toast.success(text + " successfully");
      dispatch({
        type: `CallLog/invalidateTags`,
        payload: ['CallLog']
      });
      dispatch({
        type: `AllocationForm/invalidateTags`,
        payload: ['AllocationForm']
      });
      dispatch({
        type: `leadImportForm/invalidateTags`,
        payload: ['LeadImportForm']
      });
       
      setSelectedData(null)
    } catch (error) {
      console.log("Error handling logic here:", error);
    }
  };

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  const saveData = async () => {
    console.log(`Status selected: ${selectedStatus}, Date: ${selectedDate}`);

    if (!commentValue) {
      alert('Please Enter Comment');
      return;
    }

    if (selectedStatus === null) {
      alert('Please Select a Status');
      return;
    }

    if (!window.confirm("Are you sure you want to save the details?")) {
      return;
    }

    try {

      await handleSubmitCustom(addData, storeData, "Added");

      setCommentValue('');
      setSelectedDate('');
      setSelectedData(null)
      setShowPopup(false);
      isChecked(false)
    } catch (error) {
      console.error("Error handling logic here:", error);
    }
  };

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetAllocationFormByIdQuery(selectedData);

  useEffect(() => {
    if (singleData && singleData.data && singleData.data.id) {
      setAllocatedDataId(singleData.data.id);
    }
    console.log(allocatedDataId, 'allocatedDataId');
  }, [singleData, allocatedDataId]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isSingleFetching) {
    return <Loader />;
  }
  if (isSingleLoading) {
    return <Loader />;
  }

  if (isFetching) {
    return <Loader />;
  }
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleIconClick = (index, leadId) => {
    console.log(leadId, 'leadId')
    setShowPopup(!showPopup);

    const newStates = [...rowStates];
    newStates[index] = !newStates[index];
    setRowStates(newStates);
    setIsChecked(!isChecked);

    if (selectedData == null) {
      setSelectedData(leadId);
    }
  };
  const itemsPerPage = 10;

  const totalPages = Math.ceil((allData?.data ? allData?.data : []).length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = (allData?.data ? allData?.data : []).slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleLeedChange = (status) => {
    setSelectedLeed(status);

  };
  
  const handleSelectHeading = (id) => {

    const selectedId = id
    setSelectedUser(selectedId)
  };


  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    if (status === 'FOLLOWUP') {
      setIsDatePickerOpen(true);
    } else {
      setIsDatePickerOpen(false);
    }
  };
  const handleStatusChange1 = (status) => {
    setSelectType(status);
  };

  const handleSelectType = async (value) => {
    if (value === 'selectAll') {
      setSelectedValue("");
    } else {
      setSelectedValue(value);
    }
  };
  console.log(allData.data, "alldata")
  return (
    <div>
      <div className='flex justify-between '>
    {userId !== 5 && (
  <select
    color="blue"
    label="Select Version"
    className="bg-gray-200 border-2 border-sky-800 text-gray-900 text-sm rounded-lg mx-10 my-5 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    onChange={(e) => handleSelectType(parseInt(e.target.value))}
    value={selectedValue}
  >
    <option value="" disabled hidden>
      Select a Type
    </option>
    {userType?.data?.map((value) => (
      <option key={value.id} value={value.id} className="my-5">
        {value.name}
      </option>
    ))}
  </select>
)}

{userId === 5 && (
  <>
    <select
      color="blue"
      label="Select Version"
      className="bg-gray-200 border-2 border-sky-800 text-gray-900 text-sm rounded-lg mx-10 my-5 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      onChange={(e) => handleSelectType(parseInt(e.target.value))}
      value={selectedValue}
    >
      <option value="" disabled hidden>
        Select a Type
      </option>
      {userType?.data?.map((value) => (
        <option key={value.id} value={value.id}>
          {value.name}
        </option>
      ))}
    </select>
    

    {selectedValue && (
      <select
        color="blue"
        label="Select Version"
        className="bg-gray-200 border-2 border-sky-800 text-gray-900 text-xs rounded-lg mx-10 my-5 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(e) => handleSelectHeading(e.target.value)}

        value={selectedUser}
      >
        <option value="" disabled hidden>
          Select a User
        </option>
        {user?.data?.map((value) => (
          <option key={value.id} value={value.id}>
            {value.username}
          </option>
        ))}
      </select>
    )}
  </>
)}
  
      <div className="text-lg text-gray-800 pt-5">Today CallLog</div>
      <div className="flex items-center justify-center bg-white shadow-lg rounded-lg">
        <PhoneIcon className="h-10 w-10 text-sky-800" />
        <div className="text-4xl text-sky-700 ml-2">{allData.callLogCount}</div>
    
      </div>
      
        <div className=' pt-7'>
        <button
        checked={selectType === "INTERESTED"}
        onClick={() => handleStatusChange1("INTERESTED")}
          title="INTERESTED"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-green-700 relative hover:bg-gradient-to-r hover:from-green-700 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserFollowFill /></span>
      </button>
      <button
        checked={selectType === "NOTINTERESTED"}
        onClick={() => handleStatusChange1("NOTINTERESTED")}
         title="NOTINTERESTED"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-red-700 relative hover:bg-gradient-to-r hover:from-red-700 hover:to-red-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-red-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserUnfollowFill /></span>
      </button>

      <button
        checked={selectType === "NOTRESPONSE"}
        onClick={() => handleStatusChange1("NOTRESPONSE")}
         title="NOTRESPONSE"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-purple-700 relative hover:bg-gradient-to-r hover:from-purple-700 hover:to-purple-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-purple-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserUnfollowFill /></span>
      </button>
      

      <button
       checked={selectType === "FOLLOWUP"}
       onClick={() => handleStatusChange1("FOLLOWUP")}
       title="FOLLOWUP"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-yellow-700 relative hover:bg-gradient-to-r hover:from-yellow-700 hover:to-yellow-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-yellow-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><FcLeave /></span>
      </button>
      
      
      <button
        title="DETAIL"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-sky-700 relative hover:bg-gradient-to-r hover:from-sky-700 hover:to-sky-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-400 transition-all ease-out duration-300 text-sm"
        onClick={togglePopup}
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><FcViewDetails /></span>
      </button>

      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-100 p-6 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4">Call Log Details</h2>
            <div className="m-2">
            <input
                type="date"
                value={startDate}
                onChange={handleDateChange1}
                className="border-2 border-sky-900 rounded p-2 w-28 mt-1 text-sm"
                max="9999-12-31" 
                min="0000-01-01"
            />
        </div>

        <table class="table-auto w-full border-collapse bg-white shadow-md rounded-lg overflow-hidden">
  <thead>
    <tr class="bg-sky-700 text-white text-sm font-thin tracking-widest">
      <th class="px-4 py-2 text-left">Interest</th>
      <th class="px-4 py-2 text-left">Not Interest</th>
      <th class="px-4 py-2 text-left">Follow-up</th>
    </tr>
  </thead>
  <tbody>
    <tr class="border-b border-gray-200 hover:bg-gray-300 transition duration-300 text-xs">
      <td class="px-4 py-2">
        <span class="bg-green-200 text-green-800 font-bold rounded-full px-2 py-1">
          {allData.counts.interested}
        </span>
      </td>
      <td class="px-4 py-2">
        <span class="bg-red-200 text-red-800 font-bold rounded-full px-2 py-1">
          {allData.counts.notInterested}
        </span>
      </td>
      <td class="px-4 py-2">
        <span class="bg-yellow-200 text-yellow-800 font-bold rounded-full px-2 py-1">
          {allData.counts.followup}
        </span>
      </td>
    </tr>
  </tbody>
</table>

            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition"
              onClick={togglePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <button
      className='border-2 border-sky-800 rounded p-1.5 ml-3'
      aria-checked={selectLeed === "All"}
      onClick={() => {
        setSelectType('');
        setSelectedLeed('');
      }}
      title="Refresh"
    >
      <FcRefresh />
    </button>
    <p className="pt-2 font-bold text-red-800 text-sm">{selectType? selectType:selectLeed}</p>


        </div>
   
        <div className='mx-20'>
          <button
            checked={selectLeed === "Old"}
            onClick={() => handleLeedChange("Old")}
            className="relative rounded px-4 py-1.5 mx-3 mt-6 overflow-hidden group bg-sky-600 relative hover:bg-gradient-to-r hover:from-sky-400 hover:to-sky-200 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-200 transition-all ease-out duration-300"
          >
            <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative">old</span>
          </button>

          <button
            checked={selectLeed === "Today"}
            onClick={() => handleLeedChange("Today")}
            className="relative rounded px-1 py-1.5 mx-3 overflow-hidden group bg-sky-600 relative hover:bg-gradient-to-r hover:from-sky-400 hover:to-sky-200 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-200 transition-all ease-out duration-300"
          >
            <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative">Today</span>
          </button>
          <button
            checked={selectLeed === "All"}
            onClick={() => handleLeedChange("All")}
            className="relative rounded px-4 py-1.5  mx-3 overflow-hidden group bg-sky-600 relative hover:bg-gradient-to-r hover:from-sky-400 hover:to-sky-200 text-white hover:ring-2 hover:ring-offset-2 hover:ring-sky-200 transition-all ease-out duration-300"
          >
            <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative">All</span>
          </button>
       

        </div>
      </div>

      <div className=''>
        <div className="shadow-lg rounded-lg overflow-hidden mx-4 md:mx-10 overflow-scroll">
          <div className='h-3/4'>
            <table className="border text-xs overflow-x-scroll overflow-y-scroll w-full">
            <thead className="bg-sky-800 border-2 font-sans font-thin antialiased tracking-wider sticky top-0">
                <tr className="bg-sky-800">
                  <th className="pl-5 py-5">
                    <label>
                      <div className="flex items-center justify-center cursor-pointer select-none pr-4 text-2xl text-center   ">
                        {isActive ? (
                          <FcCallback onClick={() => setIsActive(!isActive)} />
                        ) : (
                          <FcEndCall onClick={() => setIsActive(!isActive)} />
                        )}
                      </div>
                    </label>
                  </th>
                  {headingData?.data?.map((value) => (
                    <th
                      key={value.id}
                      className="flex-1 py-2 m-2 text-left bg-sky-800 text-white font-bold uppercase text-center border border-gray-400"
                    >
                      {value.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className=" divide-y divide-gray-500 border-collapse text-left w-full">
                {currentData.map((lead, leadIndex) => (
                  <tr
                    key={leadIndex}
                    className={`hover:bg-blue-300 ${leadIndex % 2 === 0 ? 'bg-white' : 'bg-gray-300'}`}
                  >
                    <td className="px-5 py-2 text-center border border-gray-400">
                      <label className="inline-flex items-center">
                        <div className="cursor-pointer select-none text-2xl">
                          {rowStates && rowStates[`${lead.id}-${leadIndex}`] ? (
                            <FcCallback onClick={() => handleIconClick(leadIndex, lead.id)} />
                          ) : (
                            <FcEndCall onClick={() => handleIconClick(leadIndex, lead.id)} />
                          )}
                        </div>
                      </label>
                    </td>
                    {headingData?.data?.map((value, valueIndex) => (
                      <td key={valueIndex} className="py-2 px-3 whitespace-nowrap text-center border border-gray-400">
                        {lead[value.name]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>

        <div className="text-right px-10 py-2">
          {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center  h-full bg-gray-800 bg-opacity-75">
  <div className="bg-white p-6 mx-auto rounded shadow-lg bg-amber-50 relative overflow-y-auto overflow-x-auto grid grid-cols-2 gap-2 custom-scrollbar">
    <button
      onClick={() => {
        setShowPopup(false);
        setSelectedData(null);
        setSelectedStatus("");
      }}
      className="absolute top-0 right-0 m-2 text-red-700 hover:text-gray-800"
    >
      <FaWindowClose />
    </button>
    {headingData?.data?.map((value) => (
      <div key={value.id} className="text-left m-1">
        <label className="text-sm text-blue-800">{value.name}</label>
        <input
          type="text"
          value={(singleData && singleData.data[value.name]) || ""}
          readOnly
          className="border border-blue-900 rounded p-2 w-full mt-1 text-sm"
        />
      </div>
    ))}

<textarea
  className="w-full h-20 p-2 border rounded focus:outline-none focus:ring-gray-300 focus:ring-1 border-blue-900 text-sm"
  name="comment"
  placeholder="Add Comment"
  value={commentValue}
  onChange={handleCommentChange}
  style={{ textTransform: 'uppercase' }}
></textarea>

    {singleData && singleData.data && (
      <>
        {singleData.data.comment && (
          <textarea
            type="text"
            value={`LAST CALL DATE: ${new Date(singleData.data.followupDate).toISOString().slice(0, 10)}, LAST CALL COMMENT: ${singleData.data.comment} `}
            readOnly
            className="border text-lime-700 rounded p-2 w-full mt-1 text-indigo-700 font-bold text-justify break-words text-sm"
            style={{ textAlign: "left", overflow: "auto" }}
          />
        )}
      </>
    )}
    <div>
      <div>
      <button
        checked={selectedStatus === "INTERESTED"}
        onClick={() => handleStatusChange("INTERESTED")}
        title= 'INTERESTED'
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-blue-700 relative hover:bg-gradient-to-r hover:from-blue-700 hover:to-blue-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-blue-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserFollowFill /></span>
      </button>

      <button
        checked={selectedStatus === "NOTINTERESTED"}
        onClick={() => handleStatusChange("NOTINTERESTED")}
        title='NOTINTERESTED'
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-red-700 relative hover:bg-gradient-to-r hover:from-red-700 hover:to-red-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-red-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserUnfollowFill /></span>
      </button>
      <button
        checked={selectedStatus === "NOTRESPONSE"}
        onClick={() => handleStatusChange("NOTRESPONSE")}
         title="NOTRESPONSE"
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-purple-700 relative hover:bg-gradient-to-r hover:from-purple-700 hover:to-purple-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-purple-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><RiUserUnfollowFill /></span>
      </button>

      <button
        checked={selectedStatus === "FOLLOWUP"}
        onClick={() => handleStatusChange("FOLLOWUP")}
        title='FOLLOWUP'
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-yellow-700 relative hover:bg-gradient-to-r hover:from-yellow-700 hover:to-yellow-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-yellow-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><FcLeave /></span>
      </button>
      <button
        onClick={handleClickOpen}
        title='History'
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-orange-700 relative hover:bg-gradient-to-r hover:from-orange-700 hover:to-orange-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-yellow-400 transition-all ease-out duration-300 text-sm"
      >
        <span className="absolute right-0 w-6 h-20 -mt-8 transition-all duration-1000 transform translate-x-8 bg-white opacity-10 rotate-12 group-hover:-translate-x-32 ease"></span>
        <span className="relative"><MdHistory /></span>
      </button>
      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 relative max-w-3xl mx-auto">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 bg-red-800 pr-1 pl-1 text-white rounded-full focus:outline-none"
            >
              &times;
            </button>
            <h2 className="text-lg font-bold mb-4 text-center">History</h2>

            <table className="min-w-full bg-white border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="px-4 py-2 border border-gray-300 bg-gray-200">Date</th>
                  <th className="px-4 py-2 border border-gray-300 bg-gray-200">Comment</th>
                </tr>
              </thead>
              <tbody>
                {singleData.data.callLogs.map((log, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border border-gray-300 text-xs">{new Date(log.followupDate).toLocaleString()}</td>
                    <td className="px-4 py-2 border border-gray-300 text-xs">{log.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <button
        onClick={saveData}
        className="relative rounded px-4 py-2 mx-2 overflow-hidden group bg-green-700 relative hover:bg-gradient-to-r hover:from-green-700 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300 text-sm"
      >
        Save Data
      </button>
      </div>
      <div className="flex px-4">
        <p className="p-4 font-bold text-red-800 text-sm">{selectedStatus}</p>
        {isDatePickerOpen && (
          <div className="mt-2">
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="border border-blue-900 rounded p-2 w-28 mt-1 text-sm"
              max="yyyy-mm-dd"
              min="yyyy-mm-dd"
            />
          </div>
        )}
      </div>
    </div>
  </div>
</div>

          )}

        </div>
      </div >
      <div className='flex-auto justify-between'>
        <div className='flex items-center justify-between pb-5 '>
          <div className='pl-20'>
            <button className='bg-blue-500 p-1 rounded hover:rounded-xl '
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaArrowLeft />
            </button>

            <span className='font-bold'>
              Page {currentPage} of {totalPages}
            </span>

            <button className=" bg-blue-500 p-1 rounded hover:rounded-xl m-5"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaArrowRight />
            </button>
          </div>
          <div className="text-right px-10 py-2">
            <button onClick={saveData} className="relative rounded px-5 py-2.5 overflow-hidden group bg-green-700 relative hover:bg-gradient-to-r hover:from-green-700 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300">
              <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
              <span className="relative">assign</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallLog;
