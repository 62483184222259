import { ACTIVE, INACTIVE } from "../Strings"

export const bloodList = [
    { show: "A +ve", value: "AP" },
    { show: "A -ve", value: "AN" },
    { show: "B +ve", value: "BP" },
    { show: "B -ve", value: "BN" },
    { show: "AB +ve", value: "ABP" },
    { show: "AB -ve", value: "ABN" },
    { show: "O +ve", value: "OP" },
    { show: "O -ve", value: "ON" }
]

export const genderList = [
    { show: 'MALE', value: 'MALE' },
    { show: 'FEMALE', value: 'FEMALE' },
    { show: 'OTHER', value: 'OTHER' }
]

export const maritalStatusList = [
    { show: 'SINGLE', value: 'SINGLE' },
    { show: 'MARRIED', value: 'MARRIED' },
    { show: 'SEPARATED', value: 'SEPARATED' }
]

export const pageType = [
    { show: 'MASTER', value: 'Masters' },
    { show: 'TRANSACTION', value: 'Transactions' },
    { show: 'ADMIN CONTROLS', value: 'AdminAccess' },
    { show: 'REPORTS', value: 'Reports' }
]

export const accessoryCategoryList = [
    { show: 'STITCHING ACCESSORIES', value: 'STITCHING' },
    { show: 'PACKING ACCESSORIES', value: 'PACKING' }
]

export const prefixCategory = [
    { show: "DEFAULT", value: "Default" },
    { show: "SPECIFIC", value: "Specific" }
]

export const employeeType = [
    { show: "PERMANENT", value: true },
    { show: "TEMPORARY", value: false }
]

export const statusDropdown = [
    {show: "ACTIVE", value: true},
    {show: "INACTIVE", value: false}
]

export const poTypes = [
    {show: "Grey Yarn", value: "GreyYarn"},
    {show: "Dyed Yarn", value: "DyedYarn"},
    {show: "Grey Fabric", value: "GreyFabric"},
    {show: "Dyed Fabric", value: "DyedFabric"},
    {show: "Accessory", value: "Accessory"},
]

export const discountTypes = [
    {show: "Flat", value: "Flat"},
    {show: "Percentage", value: "Percentage"}
]

export const diaMeasurementList = [
    {show: "CMS", value: "CMS"},
    {show: "Inches", value: "INCHES"},
    {show: "Open Width", value: "OPENWIDTH"},
    {show: "Tubuler", value: "TUBULER"},
]

export const purchasePrPi = [
    {show: "Purchase Inward", value: "PurchaseInward"},
    {show: "Purchase Return", value: "PurchaseReturn"}
]
export const showEntries = [
    { show: "10", value: "10" },
    { show: "25", value: "25" },
    { show: "50", value: "50" },
    { show: "100", value: "100" },
]
export const inwardType = [

    {show: "Purchase Inward", value: "Purchase Inward"},
    {show : "Direct Inward ", value: "Direct Inward"},
]
export const inwardReturn = [

    {show: "Purchase Return", value: "Purchase Return"},
    {show : "Direct Return", value: "Direct Return"},
]
export const inwardBill = [

    {show: "Purchase Bill", value: "Purchase Bill"},
    {show : "Direct Bill", value: "Direct Bill"},
]
export const salesTypes = [
    { show: "WHOLE SALE", value: "WHOLESALE" },
    { show: "RETAIL", value: "RETAIL" },
]
export const Tax= [
    { show: "Tax", value: "Tax" },
    { show: "Estimate", value: "Estimate" },
    { show: "All", value: "All" },
]
export const clientType= [
    { show: "Own Customer", value: "Own Customer" },
    { show: "Outside Customer", value: "Outside Customer" },
]
export const PaymentDet= [
    { show: "AMC ", value: "AMC" },
    { show: "Warrenty", value: "Warrenty" },
    { show: "Adjustment Pay", value: "Adjustment Pay" },
    { show: "Paid Service", value: "Paid Service" },
]
export const  pay= [
    { show: "30 Days  ", value: "30 Days" },
    { show: "against Deleivery", value: "against Deleivery" },
   
]

