import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PURCHASE_BILL_APIS} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const purchaseBillsApi = createApi({
  reducerPath: "purchaseBills",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["purchaseBills"],
  endpoints: (builder) => ({
    getPurchaseBills: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: PURCHASE_BILL_APIS +"/search/"+searchParams,
            method: "GET",  
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PURCHASE_BILL_APIS,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["purchaseBills"],
    }),
    getPurchaseBillsById: builder.query({
      query: (id) => {
        return {
          url: `${PURCHASE_BILL_APIS}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["purchaseBills"],
    }),
    addPurchaseBills: builder.mutation({
      query: (payload) => ({
        url: PURCHASE_BILL_APIS,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["purchaseBills"],
    }),
    updatePurchaseBills: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${PURCHASE_BILL_APIS}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["purchaseBills"],
    }),
    deletePurchaseBills: builder.mutation({
      query: (id) => ({
        url: `${PURCHASE_BILL_APIS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["purchaseBills"],
    }),
  }),
});

export const {
  useGetPurchaseBillsQuery,
  useGetPurchaseBillsByIdQuery,
  useAddPurchaseBillsMutation,
  useUpdatePurchaseBillsMutation,
  useDeletePurchaseBillsMutation,
} = purchaseBillsApi;

export default purchaseBillsApi;
