import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVICE_DET_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const serviceDetMasterApi = createApi({
  reducerPath: "serviceDetMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["ServiceDet"],
  endpoints: (builder) => ({
    getServiceDet: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: SERVICE_DET_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: SERVICE_DET_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ServiceDet"],
    }),
    getServiceDetById: builder.query({
      query: (id) => {
        return {
          url: `${SERVICE_DET_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ServiceDet"],
    }),
    addServiceDet: builder.mutation({
      query: (payload) => ({
        url: SERVICE_DET_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["ServiceDet"],
    }),
    updateServiceDet: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${SERVICE_DET_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ServiceDet"],
    }),
    deleteServiceDet: builder.mutation({
      query: (id) => ({
        url: `${SERVICE_DET_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceDet"],
    }),
  }),
});

export const {
  useGetServiceDetQuery,
  useGetServiceDetByIdQuery,
  useAddServiceDetMutation,
  useUpdateServiceDetMutation,
  useDeleteServiceDetMutation,
} = serviceDetMasterApi;

export default serviceDetMasterApi;
