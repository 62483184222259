import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Loader } from "../../../Basic/components";
import { getDateFromDateTimeToDisplay } from "../../../Utils/helper";
import secureLocalStorage from 'react-secure-storage';
import {
    useGetProductServiceQuery,
} from '../../../redux/CrmServices/ProductServiceService';
import { useGetPartyQuery } from '../../../redux/CrmServices/PartyMasterService';
import { useGetUserTypeQuery } from '../../../redux/CrmServices//UserType';
import {
    useGetEmployeeQuery,
} from '../../../redux/CrmServices//EmployeeMasterService';
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import Table from './childComponent';
import { useGetServiceDetQuery } from '../../../redux/CrmServices/ServiceDet';
import { DropdownInput } from "../../../Inputs";
import { dropDownListObject } from '../../../Utils/contructObject';
import { showEntries } from "../../../Utils/DropdownData";
import PdfFormat from './pdfFormat';


const SalesRegister = () => {
    const componentRef = useRef();
    const childRecord = useRef(0);
    const [docId, setDocId] = useState("");
    const [totalNetBillValue, setTotalNetBillValue] = useState('');
    const [currentOpenNumber, setCurrentOpenNumber] = useState("");
    const [leadCategoriesId, setLeadCategoriesId] = useState("");
    const [empId, setEmpId] = useState("");
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    );
    const [supplierId, setSupplierId] = useState("");
    const [poNo, setPoNo] = useState("");
    const [searchPoDate, setPoDate] = useState("");
    const [searchDueDate, setDueDate] = useState("");
    const [searchPoType, setSearchPoType] = useState("");
    const [supplier, setSupplier] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [id, setId] = useState("");
    const [fromDate, setfromDate] = useState(null);
    const [toDate, settoDate] = useState(null);
    const [isPdfVisible, setIsPdfVisible] = useState(false); 

   
    const handleDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setfromDate(newDate);
    };
    const handleDateChange1 = (event) => {
        const newDate = new Date(event.target.value);
        settoDate(newDate);
    };

    const [isTaxBillValue, setIsTaxBillValue] = useState("");
    const searchFields = { employeeId: empId, leadCategoriesId, supplier: supplierId, isTaxBillValue, fromDate, toDate };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    );
    const params = {
        branchId, companyId
    };
    const { data: userType } = useGetUserTypeQuery(params);

    const { data: employeeData, isFetching: isempFetching, isLoading: isempLoading } = useGetEmployeeQuery(params);
    const { data: LeadCategoriesList } =
    useGetServiceDetQuery({ params });
    const LeadCategories = LeadCategoriesList?.data ? LeadCategoriesList.data : [];

    const { data, isLoading, isFetching } = useGetProductServiceQuery({ params: { branchId, companyId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber } });
    const purData = data?.data ? data.data : [];
    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    };
    useEffect(() => {
        if (data?.totalCount) {
            setTotalCount(data?.totalCount);
        }
    }, [data, isLoading, isFetching]);

    const { data: supplierList } =
    useGetPartyQuery({ params });
    const supplierData = supplierList?.data ? supplierList.data : [];
    useEffect(() => {
        if (purData.length > 0) {
            const totalNetBill = purData.reduce((total, dataObj) => {
                return total + (dataObj.netBillValue || 0);
            }, 0);

            setTotalNetBillValue(totalNetBill);
        }
    }, [purData]);

    useEffect(() => {
        setCurrentPageNumber(1);
    }, [poNo, searchPoDate, supplier, searchPoType, searchDueDate]);
    const tableRef = useRef(null);
    console.log(supplierData,"supplier")

    if (isLoading || isFetching) return <Loader />;

    const isLoadingIndicator = isLoading || isFetching;
    return (
        <>
            <div className="flex flex-col w-full h-[95%] overflow-auto">
                <div className="flex items-center justify-between w-full page-heading p-1 text-white">
                    <div className="heading text-center whitespace-nowrap">Job Card Report</div>

                    <div className="switch-buttons flex">
                        <div className="switch-button text-white">
                            <DropdownInput
                                name="ServiceDetail"
                                options={dropDownListObject(
                                    id ? LeadCategories : LeadCategories.filter(type => type.name).filter(type => type.id),
                                    "name",
                                    "id",
                                    true
                                )}
                                type="data"
                                required={true}
                                value={leadCategoriesId}
                                setValue={(selectedValue) => {
                                    setLeadCategoriesId(selectedValue === "all" ? undefined : selectedValue);
                                }}
                            />
                        </div>

                        <div className="switch-button text-white">
                            <DropdownInput
                                name="Employee"
                                options={employeeData ? dropDownListObject(
                                    id ? employeeData.data : employeeData.data.filter(item => item.active),
                                    "name",
                                    "id",
                                    true
                                ) : []}
                                value={empId}
                                setValue={(selectedValue) => {
                                    setEmpId(selectedValue === "all" ? undefined : selectedValue);
                                }}
                                required={true}
                            />
                        </div>

                        <div className="switch-button  text-white">
                            <DropdownInput
                                name="Customer"
                                options={dropDownListObject(
                                    id ? supplierData : supplierData.filter(value => value.isCustomer).filter(item => item.active),
                                    "name",
                                    "id",
                                    true
                                )}
                                value={supplierId}
                                setValue={(selectedValue) => { setSupplierId(selectedValue === "all" ? undefined : selectedValue); }}
                                required={true}
                                disabled={childRecord.current > 0}
                            />
                        </div>

                        <div className="switch-button text-white">
                            <div className='mr-7 pt-1 '>
                                <label className='text-xs pr-3'>from</label>
                                <input
                                    type="date"
                                    value={fromDate ? fromDate.toISOString().split('T')[0] : ''}
                                    onChange={handleDateChange}
                                    className="mr-5 text-sm text-black rounded"
                                />
                                <label className='text-xs pr-3'>To</label>
                                <input
                                    type="date"
                                    value={toDate ? toDate.toISOString().split('T')[0] : ''}
                                    onChange={handleDateChange1}
                                    className="mr-2 text-sm text-black rounded"
                                />
                            </div>
                        </div>
                        <select
                            value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)}
                            className="h-6 w-40 border border-gray-500 rounded text-black text-xs mt-1"
                        >
                            {showEntries.map((option) => (
                                <option key={option.value} value={option.value}>{option.show}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <>
                    <div className="h-[500px] overflow-auto">

                        <table ref={tableRef} id="table-to-xls" className="table-fixed text-center w-full mt-10 border border-2 border-black ">
                            <thead className=" table-header border-black ">
                                <tr className='h-2'>
                                    <th className="  top-0 stick-bg w-10">
                                        S. no.
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        Bill No
                                    </th>
                                    <th className="  top-0 stick-bg table-data ">
                                        <label>Bill Date</label>
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        <label>Customer</label>
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        <label>Client Type</label>
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        <label>Service Detail</label>
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        <label>pay Type</label>
                                    </th>
                                    <th className="  top-0 stick-bg table-data">
                                        <label>Employee Name</label>
                                    </th>
                                </tr>
                            </thead>
                            {isLoadingIndicator ?
                                <tbody>
                                    <tr>
                                        <td>
                                            <Loader />
                                        </td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="">
                                    {purData.map((dataObj, index) => (
                                        <Fragment key={index}>
                                            <tr
                                                className={` table-row ${(currentOpenNumber === index) ? "border-2 border-black " : ""}`}
                                                onClick={() => {
                                                    if (index === currentOpenNumber) {
                                                        setCurrentOpenNumber("")
                                                    } else {
                                                        setCurrentOpenNumber(index)
                                                        setId(dataObj.id)
                                                    }
                                                }}>
                                                <td className='py-1 border-2 border-gray-400'> {(index + 1) + (currentPageNumber - 1)}</td>
                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj.docId}</td>
                                                <td className='py-1 text-left border-2 border-gray-400'> {getDateFromDateTimeToDisplay(dataObj?.createdAt)}</td>
                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj?.supplier?.name} </td>
                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj?.clientType} </td>
                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj?.ServiceDet?.name} </td>
                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj?.payType} </td>

                                                <td className='py-1 text-left border-2 border-gray-400'>{dataObj?.Employee?.name} </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={6}>
                                                    {(currentOpenNumber === index)
                                                        &&
                                                        <Table productionDeliveryDetails={dataObj?.ProductServiceItems ? dataObj?.ProductServiceItems : []} date={dataObj?.createdAt} netBillValue={dataObj?.netBillValue} />
                                                    }
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                </tbody>
                            }
                        </table>
                    </div>
                </>
                {isPdfVisible && ( 
                    <div id="pdf-content">
                     
                            <PdfFormat purData= {purData}  />
                      
                    </div>
                )}
                <div className='right-0 relative flex gap-3'>
                <button
                className='bg-green-800 rounded w-32 ml-auto absolute right-20 bottom-0 p-2      mr-3 mb-10 text-white'
                onClick={() => setIsPdfVisible(!isPdfVisible)}
                 >
                 {isPdfVisible ? 'Back' : 'Download'}
                 </button>

                </div>
                {!isPdfVisible ? 
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                    pageCount={Math.ceil(totalCount / dataPerPage)}
                    marginPagesDisplayed={1}
                    onPageChange={handleOnclick}
                    containerClassName={"flex justify-center m-2 gap-5 items-center"}
                    pageClassName={"border custom-circle text-center"}
                    disabledClassName={"p-1 bg-gray-200"}
                    previousLinkClassName={"border p-1 text-center"}
                    nextLinkClassName={"border p-1"}
                    activeClassName={"bg-blue-900 text-white px-2"}
                />: ""}
            </div>
        </>
    );
}

export default SalesRegister;
