export const EMPLOYEE_API = "employees";
export const CITY_API = "cities";
export const STATE_API = "states";
export const COUNTRY_API = "countries";
export const DEPARTMENT_API = "departments";
export const LOGIN_API = "users/login";
export const COMPANY_API = "companies";
export const BRANCHES_API = "branches";
export const USERS_API = "users";
export const PAGES_API = "pages";
export const PAGES_GROUP_API = "pageGroup";
export const ROLES_API = "roles";
export const SUBSCRIPTION_API = "subscriptions";
export const FIN_YEAR_API = "finYear";
export const EMPLOYEE_CATEGORY_API = "employeeCategories";
export const LEAD_API = "leadCategories"
export const LEAD_MASTER_API = "leadMaster"
export const HEADING_API = "heading";
export const ALLOCATION_API = "allocation";
export const CALLLOG_API = "calllog"
export const USER_TYPE = "userType"
export const USERTYPE_DETAIL = "usetTypeDetail"
export const PARTY_API = "party"
export const PRODUCT_BRAND_API = "productBrand"
export const SERVICE_DET_API = "serviceDet"
export const  PRODUCT_SERVICE_API  = "productservice"
export const PRODUCT_CATEGORY_API = "productCategory"
export const PRODUCT_API = "product"
export const PURCHASE_BILL_API = "purchaseBill"
export const STOCK_API = "stock"
export const UOM_API = "uom"
export const PARTY_CATEGORY_API = "partyCategories"
export const PURCHASE_INWARD_API = "purchaseInwardOrReturn"
export const PURCHASE_RETURN_API = "purchaseReturn"
export const SALES_BILL_API = "salesBill"
export const QUATATION_API = "quatation"
export const LOANTENURE_API = "loanTenure"
export const PURCHASE_BILL_APIS = "purchaseBills"
export const TAX_TEMPLATE_API = 'taxTemplate'
export const TAX_TERM_API = 'taxTerm'
export const SIMIMEIAPI = 'simimei'
export const SALES_RETURN_API = "salesReturn"
export const BANKMASTERAPI = "bankMaster"
export const LOANTYPEAPI = "loanType"
export const BUYER_API = "Buyer"





    



