import React, { useEffect } from 'react';
import { useState } from 'react';
import { useGetProductQuery } from '../../../redux/CrmServices/ProductMasterService';
import { useGetProductCategoryQuery } from '../../../redux/CrmServices/ProductCategoryServices';
import { useGetProductBrandQuery } from '../../../redux/CrmServices/ProductBrandService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';
import { DELETE } from '../../../icons';
import { PLUS } from '../../../icons';
import { EYE } from "../../../icons";
import { toast } from "react-toastify";
import Imei from './Imei';

const PoBillItems = ({ id, readOnly, setPoBillItems, poBillItems, supplierId, setInwardItemSelection, inwardTypes }) => {
    const [formReport, setFormReport] = useState(false);
    const [index, setIndex] = useState(false);
    const [productId, setProductId] = useState("");
    const [poInwardItemsId,setPoInwardItemsId] = useState("")
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") }
    const { data: productBrandList } =
        useGetProductBrandQuery({ params });
    console.log(productBrandList, "productBrandList")

    const { data: productCategoryList } =
        useGetProductCategoryQuery({ params });

    const { data: productList } = useGetProductQuery({ params });
    console.log(productList, "product")

    function handleInputChange(value, index, field) {
        const newBlend = structuredClone(poBillItems);
        newBlend[index][field] = value;
        if (field === "uomId") {
            const productId = newBlend[index]["productId"];
            console.log(productId, "productId")
            let price = getProductUomPriceDetails(productId).find(i => parseInt(i.uomId) === parseInt(value))
            let Iqty = getProductIqtyDetails(productId).find(i => parseInt(i.Iqty) === parseInt(value))
            newBlend[index]["price"] = price?.price ? price?.price : 0
            newBlend[index]["Iqty"] = Iqty?.Iqty ? Iqty?.Iqty : 0

        }
        setPoBillItems(newBlend);
    };

    function deleteRow(index) {
        setPoBillItems(prev => prev.filter((_, i) => i !== index))
    }
    function addNewRow() {
        setPoBillItems(prev => [

            { productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", price: "0.00", amount: "0.000", salePrice: '0.00' }, ...prev
        ]);
    }


    function getTotal(field1, field2) {
        const total = poBillItems.reduce((accumulator, current) => {

            return accumulator + parseFloat(current[field1] && current[field2] ? current[field1] * current[field2] : 0)
        }, 0)
        return parseFloat(total)

    }
    function getProductUomPriceDetails(productId) {
        const items = findFromList(productId, productList?.data ? productList?.data : [], "ProductUomPriceDetails")
        return items ? items : []
    }
    function getProductIqtyDetails(productId) {
        const items = findFromList(productId, productList?.data ? productList?.data : [], "ProductIqtyPriceDetails")
        return items ? items : []
    }

    if (!productBrandList || !productCategoryList || !productList) return <Loader />
    console.log(inwardTypes)


    return (
        <>



            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className=" border border-gray-500 text-xs table-auto  w-full font-sans">
                    <thead className='bg-sky-700 top-0 border-b border-gray-500 text-white'>
                        <tr className=''>
                            <th className="table-data  w-2 text-center p-0.5">S.no</th>
                            <th className="table-data ">Product Brand<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data ">Product Category<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data ">Product Name<span className="text-red-500 p-5">*</span></th>
                            <th className="table-data ">Uom<span className="text-red-500 p-5">*</span></th>

                            {(inwardTypes === "Purchase Inward") ? (
                                <>
                                    <th className="table-data  w-20">PoQty<span className="text-red-500 p-0.5">*</span></th>
                                    <th className="table-data  w-20">AlreadyInwardQty<span className="text-red-500 p-0.5">*</span></th>
                                    <th className="table-data  w-20">AlreadyReturnQty<span className="text-red-500 p-0.5">*</span></th>
                                    <th className="table-data  w-20">BalQty<span className="text-red-500 p-0.5">*</span></th>
                                </>
                            ) : null}
                            <th className="table-data  w-20">InwardQty<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data  w-16">Price<span className="text-red-500 p-0.5">*</span></th>
                            <th className="table-data ">Imei Detail</th>

                            <th className="table-data  w-16 p-0.5">Amount</th>
                            {(inwardTypes === "Purchase Inward") ? <th className="table-data text-right bg-white pr-1 w-10">
                                <button
                                    type='button'
                                    onClick={() => {
                                        if (!supplierId) {
                                            toast.info("Please Select a Suppplier", { position: "top-center" })
                                            return
                                        }
                                        setInwardItemSelection(true)
                                    }}
                                    className='text-xs text-red-600 item-center pr-2 text-lg '>{EYE}
                                </button>
                            </th> : <th className="table-data  w-10 p-0.5 bg-white text-sky-800 text-lg">
                                <button onClick={addNewRow}>{PLUS}</button></th>}

                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto h-full w-full'>


                        {(poBillItems ? poBillItems : []).map((item, index) =>
                            <tr key={index} className="w-full table-row">
                                <td className="table-data w-2 text-left px-1 py-1">
                                    {index + 1}
                                </td>

                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productBrandId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productBrandId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productBrandId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productBrandId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productBrandList.data : productBrandList.data.filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productCategoryId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productCategoryId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productCategoryId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productCategoryId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productCategoryList.data : productCategoryList.data.filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "productId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.productId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "productId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "productId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? productList.data : productList.data.filter(value => parseInt(value.productBrandId) === parseInt(item.productBrandId) && parseInt(value.productCategoryId) === parseInt(item.productCategoryId)).filter(item => item.active)).map((brand) =>
                                            <option value={brand.id} key={brand.id}>
                                                {brand.name}
                                            </option>)}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                        className='text-left w-full rounded py-1 table-data-input'
                                        value={item.uomId}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "uomId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {getProductUomPriceDetails(item.productId).map((uom) =>
                                            <option value={uom.uomId} key={uom.uomId}>
                                                {uom.Uom.name}
                                            </option>)}
                                    </select>
                                </td>

                                {(inwardTypes === "Purchase Inward") ? <>
                                    <td className='table-data'>
                                        <input
                                            type="number"
                                            className="text-right rounded py-1 px-1 w-16 table-data-input"

                                            value={(!item.qty) ? 0 : item.qty}
                                            disabled={readOnly}

                                        />

                                    </td>
                                    <td className='table-data'>
                                        <input
                                            type="number"
                                            className="text-right rounded py-1 px-1 w-16 table-data-input"

                                            value={(!item.AIqty) ? 0 : item.AIqty}
                                            disabled={readOnly}
                                        />
                                    </td>


                                    <td className='table-data'>
                                        <input
                                            type="number"
                                            className="text-right rounded py-1 px-1 w-16 table-data-input"

                                            value={(!item.ARqty) ? 0 : item.ARqty}
                                            disabled={readOnly}
                                        />
                                    </td>
                                    <td className='table-data'>
                                        <input
                                            type="number"
                                            className="text-right rounded py-1 px-1 w-16 table-data-input"
                                            value={(!(item.AIqty)) ? 0 : (parseFloat(item.qty) - parseFloat(item.AIqty) + parseFloat(item.ARqty))}
                                            disabled={readOnly}

                                        />
                                    </td>
                                </> : null}
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className={`text-right rounded py-1 px-1 w-16 table-data-input ${(!item.Iqty ? "" : item.Iqty > (parseFloat(item.qty) - parseFloat(item.AIqty) + parseFloat(item.ARqty))) && (inwardTypes === "Purchase Inward") ? toast.info("Please fill Below BalQty...!", { position: "top-center" }) : ''}`}
                                        value={(!item.Iqty) ? 0 : item.Iqty}
                                        disabled={readOnly}
                                        onChange={(e) => handleInputChange(e.target.value, index, "Iqty")}
                                        onBlur={(e) => handleInputChange(parseFloat(e.target.value), index, "Iqty")}
                                    />
                                </td>


                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"

                                        value={(!item.price) ? 0.000 : item.price}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "price");

                                        }
                                        }
                                    />
                                </td>
                                    <td className='table-data'>
                                        <div style={{ position: 'relative' }}>

                                            <span
                                                className=' pl-2 cursor-pointer text-center '
                                                style={{
                                                    backgroundImage: 'linear-gradient(to right, red, blue)',
                                                    WebkitBackgroundClip: 'text',
                                                    color: 'transparent',
                                                    zIndex: '1',

                                                }}
                                                onClick={() => {
                                                    setProductId(item.productId);
                                                    setPoInwardItemsId(item.poInwardItemsId)
                                                    setIndex(index);
                                                    handleInputChange(true, index, "isOpen")
                                                }}
                                            >
                                                Click Here
                                            </span>
                                        </div>

                                        {item?.isOpen && <Imei key={item?.Iqty} setFormReport={setFormReport} formReport={formReport} productList={productList} id={item.productId} productId={productId}
                                            poBillItems={poBillItems} setPoBillItems={setPoBillItems} indexs={index} poInwardItemsId= {poInwardItemsId} readOnly={readOnly} />}
                                    </td>
                          
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"

                                        value={(!item.Iqty || !item.price) ? 0 : (parseFloat(item.Iqty) * parseFloat(item.price)).toFixed(2)}
                                        disabled={readOnly}

                                    />
                                </td>

                                <td className="border border-gray-500 text-xs text-center">
                                    <button
                                        type='button    '
                                        onClick={() => {
                                            deleteRow(index)
                                        }}
                                        className='text-xs text-red-600 '>{DELETE}
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr className='bg-sky-700 w-full border border-gray-400 h-7 font-bold text-white'>
                            <td className="table-data text-center w-10 font-bold" colSpan={inwardTypes === "Purchase Inward" ? 12 : 8}>Total</td>
                            <td className="table-data text-right pr-1 w-10">{getTotal("Iqty", "price").toFixed(2)} </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PoBillItems