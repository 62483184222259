import React, { useEffect } from 'react';
import { useGetProductQuery } from '../../../redux/CrmServices/ProductMasterService';
import { useGetLoanMasterQuery } from '../../../redux/CrmServices/LoanTypeService';
import { useGetBankMasterQuery } from '../../../redux/CrmServices/BankService';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';

const PoBillItems = ({ id, readOnly, setPoBillItems, poBillItems, isTaxBill, date }) => {
    const params = { companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId") };
    const { data: productBrandList } = useGetBankMasterQuery({ params });
    const { data: productCategoryList } = useGetLoanMasterQuery({ params });
    const { data: productList } = useGetProductQuery({ params });
    

    const handleInputChange = (value, index, field) => {
        console.log(value,"value for Date")
        const updatedItems = [...poBillItems];
        updatedItems[index][field] = value;

        if (field === "lstart" || field === "ttenure") {
            const startDate = updatedItems[index].lstart;
            const tenure = updatedItems[index].ttenure;
            if (startDate && tenure) {
                updatedItems[index].lend = calculateEndDate(startDate, tenure);
            }
        }   

        setPoBillItems(updatedItems);
    };

    const calculateEndDate = (startDate, tenure) => {
        const date = new Date(startDate);
        date.setMonth(date.getMonth() + parseInt(tenure));
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        if (poBillItems.length >= 10) return;
        setPoBillItems(prev => {
            const newArray = Array.from({ length: 10 - prev.length }, () => ({
                productCategoryId: "", productBrandId: "", productId: "", stockQty: "0", qty: "0", loanAmount: "0.00", amount: "0.000", ttenure: "", ctenure: "", emi: "", lstart: "", lend: ""
            }));
            return [...prev, ...newArray];
        });
    }, [setPoBillItems, poBillItems]);

    if (!productBrandList || !productCategoryList || !productList) return <Loader />;

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className="relative w-full overflow-y-auto py-1">
            <table className="border border-gray-500 text-xs table-auto w-full">
                <thead className="bg-sky-700 border-b border-gray-500 text-white tracking-widest font-thin font-sans">
                    <tr className="p-3">
                        <th className="table-data w-2 text-center p-0.5">S.no</th>
                        <th className="table-data">Name Of the Bank<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Loan Type<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Loan Amount<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data">Total Tenure</th>
                        <th className="table-data">Closed Tenure</th>
                        <th className="table-data w-28">Balance Tenure<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-28">Emi Amount<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Loan Start Date<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Loan End Date<span className="text-red-500 p-0.5">*</span></th>
                        <th className="table-data w-32">Emi Status<span className="text-red-500 p-0.5">*</span></th>
                    </tr>
                </thead>
                <tbody>
                    {poBillItems.map((item, index) => (
                        <tr key={index} className="table-row">
                            <td className="table-data w-2 text-left px-1 py-1">{index + 1}</td>
                            <td className="table-data">
                                <select
                                    className="text-left w-full rounded py-1 table-data-input"
                                    value={item.bankId}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "productBrandId")}
                                >
                                    <option hidden></option>
                                    {(id ? productBrandList?.data : productBrandList?.data?.filter(brand => brand.active))?.map(brand => (
                                        <option value={brand.id} key={brand.id}>{brand.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td className="table-data">
                                <select
                                    className="text-left w-full rounded py-1 table-data-input"
                                    value={item.loanTypeId}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "productCategoryId")}
                                >
                                    <option hidden></option>
                                    {(id ? productCategoryList?.data : productCategoryList?.data?.filter(category => category.active))?.map(category => (
                                        <option value={category.id} key={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right rounded py-1 px-1 table-data-input"
                                    value={item.loanAmount || 0.000}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "loanAmount")}
                                    onBlur={(e) => handleInputChange(parseFloat(e.target.value).toFixed(3), index, "loanAmount")}
                                />
                            </td>
                            <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={item.ttenure || ""}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "ttenure")}
                                />
                            </td>
                            <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={item.ctenure || ""}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "ctenure")}
                                />
                            </td>
                            <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={item.ttenure ? item.ttenure - item.ctenure : 0}
                                    disabled={readOnly}
                                />
                            </td>
                            <td className="table-data">
                                <input
                                    type="number"
                                    className="text-right rounded py-1 px-1 w-72 table-data-input"
                                    value={item.emi || 0.000}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "emi")}
                                    onBlur={(e) => handleInputChange(parseFloat(e.target.value).toFixed(3), index, "emi")}
                                />
                            </td>
                            <td className="table-data">
                                <input
                                    type="date"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={item.lstart ? new Date(item.lstart).toISOString().split('T')[0] : ""}
                                    disabled={readOnly}
                                    onChange={(e) => handleInputChange(e.target.value, index, "lstart")}
                                />
                            </td>

                            <td className="table-data">
                                <input
                                    type="date"
                                    className="text-right w-full rounded py-1 px-1 table-data-input"
                                    value={item.lend ? new Date(item.lend).toISOString().split('T')[0] : ""}
                                    disabled={true}
                                />
                            </td>
                            <td className="table-data">
                                <span
                                    className={`text-right w-full rounded py-1 px-1 table-data-input ${item.lend <= today ? 'text-blue-500' : 'text-green-500'}`}
                                >
                                    {item.lend <= today ? 'Not Active' : 'Active'}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PoBillItems;
