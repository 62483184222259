import React, { useEffect, useState } from 'react';
import { useGetPartyQuery } from "../../../redux/CrmServices/PartyMasterService";
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetSalesBillQuery } from '../../../redux/CrmServices/SalesBillService';

const DirectInwardForm = ({ setInwardItems, supplierId }) => {
  const branchId = secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "currentBranchId");
  const [searchDocId, setSearchDocId] = useState("");
  const [searchBillDate, setSearchBillDate] = useState("");
  const [searchSupplierDcNo, setSearchSupplierDcNo] = useState("");
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const handleCheckboxChange = (obj) => {
    setInwardItems(prevState => {
      const index = prevState.findIndex(item => item.id === obj.id);
      if (index !== -1) {
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
      } else {
        return [...prevState, obj];
      }
    });
  };

  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  };

  const searchFields = { searchDocId, searchBillDate, searchSupplierName, searchSupplierDcNo };

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [dataPerPage, searchDocId, searchBillDate, searchSupplierName, searchSupplierDcNo]);

  const companyId = secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId");
  const params = { branchId, companyId };

  const { data: allData, isLoading, isFetching } = useGetSalesBillQuery({
    params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, salesBillItems: true, supplierId }
  });

  const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } = useGetPartyQuery({ params });

  useEffect(() => {
    if (allData?.totalCount) {
      setTotalCount(allData.totalCount);
    }
  }, [allData, isLoading, isFetching]);

  const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading;

  return (
    <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
      <div className="md:flex md:items-center md:justify-between page-heading p-1">
        <div className="heading text-center md:mx-10">Direct Return</div>
        <div className="sub-heading justify-center md:justify-start items-center">
          <label className="text-white text-sm rounded-md m-1 border-none">Show Entries</label>
          <select value={dataPerPage} onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
            {showEntries.map((option, index) => <option key={index} value={option.value}>{option.show}</option>)}
          </select>
        </div>
      </div>
      <div className="h-[500px] overflow-auto">
        <table className="table-fixed text-center w-full">
          <thead className="border-2 table-header">
            <tr className='h-2'>
              <th className="center w-10">
                <label>
                  <input type="checkbox" className="checkbox w-4 h-4 text-center" />
                </label>
              </th>
              <th className="border-2 top-0 stick-bg w-10">S. no.</th>
              {['Po.No', 'Po.Date', 'ProductCategory', 'ProductBrand', 'Product'].map((header, index) => (
                <th key={index} className="border-2 top-0 stick-bg">
                  <div>{header}</div>
                  <input type="text" className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                    placeholder="Search" value={
                      header === 'Po.No' ? searchDocId :
                        header === 'Po.Date' ? searchBillDate :
                          header === 'ProductCategory' ? searchSupplierName :
                            header === 'ProductBrand' ? searchSupplierName :
                              searchSupplierName
                    }
                    onChange={(e) => {
                      if (header === 'Po.No') setSearchDocId(e.target.value);
                      else if (header === 'Po.Date') setSearchBillDate(e.target.value);
                      else setSearchSupplierName(e.target.value);
                    }}
                  />
                </th>
              ))}
            </tr>
          </thead>
          {isLoadingIndicator ?
            <tbody>
              <tr>
                <td colSpan="7"><Loader /></td>
              </tr>
            </tbody>
            :
            <tbody className="border-2">
              {allData?.data.map((dataObj, index) => (
                <tr key={dataObj.id} className="border-2 table-row cursor-pointer">
                  <td className='py-1'>
                    <input
                      type="checkbox"
                      className="checkbox w-4 h-4"
                      onChange={() => handleCheckboxChange(dataObj)}
                    />
                  </td>
                  <td className='py-1'>{(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                  <td className='py-1'>{dataObj.SalesBill.docId}</td>
                  <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.SalesBill.createdAt)}</td>
                  <td className='py-1'>{dataObj.ProductCategory.name}</td>
                  <td className='py-1'>{dataObj.ProductBrand.name}</td>
                  <td className='py-1'>{dataObj.Product.name}</td>
                </tr>
              ))}
            </tbody>
          }
        </table>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
        pageCount={Math.ceil(totalCount / dataPerPage)}
        marginPagesDisplayed={1}
        onPageChange={handleOnclick}
        containerClassName={"flex justify-center m-2 gap-5 items-center"}
        pageClassName={"border custom-circle text-center"}
        disabledClassName={"p-1 bg-gray-200"}
        previousLinkClassName={"border p-1 text-center"}
        nextLinkClassName={"border p-1"}
        activeClassName={"bg-blue-900 text-white px-2"}
      />
    </div>
  );
};

export default DirectInwardForm;
