import React, { useEffect, useState } from 'react';
import { useGetHeadingCategoriesMasterQuery } from '../../../redux/CrmServices/HeadingCategoriesMasterServices';
import { useGetAllocationFormQuery } from '../../../redux/CrmServices/AllocationForm';
import { useAddAllocationFormMutation } from '../../../redux/CrmServices/AllocationForm';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { useGetUserFormQuery } from '../../../redux/CrmServices/UserForm';
import secureLocalStorage from "react-secure-storage";
import { useGetUserTypeQuery } from "../../../redux/CrmServices/UserType"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { FcClearFilters } from "react-icons/fc";
import { Loader } from '../../../Basic/components';
import { FcRefresh } from "react-icons/fc";
const AllocationForm = () => {
  const dispatch = useDispatch()

  const { data } = useGetHeadingCategoriesMasterQuery({});
  const [Id, setId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [addData] = useAddAllocationFormMutation();
  const [storeData, setStoreData] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const { data: allData, isLoading, isFetching } = useGetAllocationFormQuery({ params: { selectedValue} })
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [slicedData, setSliceData] = useState([])

  const handleRefresh = () => {
 
    window.location.reload(); 
  };

  const { data: user } = useGetUserFormQuery({
    params: { key: selectedValue }
  })
  useEffect(() => {
    const createdById = secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userId");
    setStoreData({
      selectedUser,
      selectedData,
      createdById,
      selectedValue,
    });
  }, [selectedUser, selectedData, selectedValue])
  const { data: userType } = useGetUserTypeQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil((allData?.data ? allData?.data : []).length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = (allData?.data ? allData?.data : []).slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData = await callback(data).unwrap();

      if (returnData && returnData.data && returnData.data.id) {


        setId(returnData.data.id);
        toast.success(text + " successfully");
        dispatch({
          type: `AllocationForm/invalidateTags`,
          payload: ['AllocationForm'],
        });
        dispatch({
          type: `CallLog/invalidateTags`,
          payload: ['CallLog'],
        });
        setSliceData([])
      } else {
        console.error("Invalid data structure received");
      }
    } catch (error) {
      console.error("Error handling logic here:", error);
    }
  };

  const saveData = () => {

    if (!selectedUser) {
      alert('Please select a User');
      return;
    }
    if (!selectedData || selectedData.length === 0) {
      alert('Please select a Data....');
      return;
    }
    if (!selectedValue || selectedValue.length === 0) {
      alert('Please Select a Type')
      return;
    }

    if (!window.confirm("Are you sure you want to save the details?")) {
      return;
    }

    else {
      handleSubmitCustom(addData, storeData, "Added");
      setSelectedUser(null);
      setSelectedData([]);
      selectedValue(null)
    }
  };


  const handleSelectHeading = (id) => {

    const selectedId = id
    setSelectedUser(selectedId)
  };
  const handleSelectType = (value) => {
    if (value === 'selectAll') {
      const allValues = userType?.data?.map((item) => item.id) || [];
      setSelectedValue('selectAll');
    } else {
      setSelectedValue((prevSelectedValue) => {
        return prevSelectedValue === value ? '' : value;
      });
    }
  };

  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userId"
    ),
  };




  const handleRowClick = (leadId) => {
    setIsChecked(!isChecked);
    if (selectedData.includes(leadId)) {
      setSelectedData(selectedData.filter((id) => id !== leadId));
    } else {
      setSelectedData([...selectedData, leadId]);
    }
    console.log(`Row with ID ${leadId} clicked`);

  };

  const handleCheckboxChange = (leadId) => {
    setIsChecked(!isChecked);

    if (selectedData.includes(leadId)) {
      setSelectedData(selectedData.filter((id) => id !== leadId));
    } else {
      setSelectedData([...selectedData, leadId]);
    }

  };
  
  const [isToBe, setIsToBe] = useState(false);

  const toggleSwitch = () => {
    setIsToBe(!isToBe);
  };
 
console.log(isToBe,"isToBe")

  if (isLoading) {
    return <div className="spinner-container">
      <Loader />
    </div>
  }

  if (isFetching) {
    return <div className="spinner-container">
      <Loader />
    </div>
  }
  const handleSelectAllCheckboxChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);

    if (slicedData && slicedData.length > 0) {
      const selectedIds = slicedData.map((item) => item.id);
      setSelectedData(selectedIds);
    } else {
      if (!selectAll) {
        const allLeadIds = allData?.data?.map((lead) => lead.id) || [];
        setSelectedData(allLeadIds);
      } else {
        setSelectedData([]);
      }
    }
  };

  const handleFilterClick = () => {
    const newSlicedData = allData?.data?.slice(fromValue, toValue);
    setSliceData(newSlicedData);

    console.log(newSlicedData, 'slicedata');
  }



  return (
    <div>
      <div className='flex'>
        <select
          color="blue"
          label="Select Version"
          className="bg-gray-200 border-2 border-sky-800 text-gray-900 text-sm rounded-lg mx-10 my-5 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500  dark:focus:border-blue-500"
          onChange={(e) => handleSelectType(parseInt(e.target.value))}
          value={selectedValue}
        >
          <option value="" disabled hidden>
            Select a Type
          </option>
          {userType?.data?.map((value) => (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          ))}
        </select>

        {selectedValue && (
          <select
            color="blue"
            label="Select Version"
            className="bg-gray-200 border-2 border-sky-800 text-gray-900 text-xs rounded-lg mx-10 my-5 focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500  dark:focus:border-blue-500"
            onChange={(e) => handleSelectHeading(e.target.value)}
            value={selectedUser}
          >
            <option value="" disabled hidden>
              Select a User
            </option>
            {user?.data?.map((value) => (
              <option key={value.id} value={value.id}>
                {value.username}
              </option>
            ))}
          </select>
        )}
        <div className="flex items-center px-10 py-2 m-2">
          <div className="flex">
            <input
              type="text"
              placeholder="from"
              className="text-sm px-2 py-1 w-12 border-2 rounded border-sky-800  mr-3"
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
            />
            <input
              type="text"
              placeholder="To"
              className="text-sm px-2 py-1 w-12 border-2 rounded border-sky-800    mr-3"
              value={toValue}
              onChange={(e) => setToValue(e.target.value)}
            />
          </div>
         <button
      onClick={handleFilterClick}
      title="Filter"
      className="bg-sky-600 rounded p-2 ml-auto"
    >
      <FcClearFilters />
    </button>
    <button
      title="Refresh"
      className="border-2 border-sky-800 rounded p-1.5 ml-3"
      onClick={handleRefresh}
    >
      <FcRefresh />
    </button>
    <span className={`ml-10 pr-3 pl-3 ${isToBe ? 'text-gray-500' :  'text-green-500'   }`}>Not Allocate</span>
      <div
        onClick={toggleSwitch}
        className={`relative inline-block w-10 h-6 transition duration-200 ease-in-out rounded-full cursor-pointer ${
          isToBe ?'bg-green-500' : 'bg-gray-500' 
        }`}
      >
        <div
          className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition transform ${
            isToBe ? 'translate-x-4' : ''
          }`}
        ></div>
      </div>
      <span className={`ml-2 ${!isToBe ?'text-gray-500' : 'text-green-500' }`}>Allocate</span>
        </div>

      </div>
      <div className="shadow-lg rounded-lg overflow-hidden mx-4 md:mx-10 overflow-scroll">
        <div className='h-3/4'>
        <table className="border text-xs overflow-x-scroll overflow-y-scroll w-full">
        <thead className="bg-sky-800 border-2 font-sans font-thin antialiased tracking-wider sticky top-0">
        <tr className="bg-sky-800">
                <th className="center py-5">
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox w-4 h-4  text-center"
                      checked={
                        slicedData && slicedData.length > 0
                          ? (slicedData.data || []).every((i) => selectedData.includes(i.id))
                          : (allData?.data || []).every((i) => selectedData.includes(i.id))
                      }

                      onChange={handleSelectAllCheckboxChange}
                    />
                  </label>
                </th>
                {data?.data?.map((value) => (
                  <th
                    key={value.id}
                    className="flex-1 py-2 px-3 text-left bg-sky-800 text-white font-bold uppercase  text-center border border-gray-300"
                  >
                    {value.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className=" divide-y divide-gray-500  text-left">
              {slicedData && slicedData.length > 0 ? (
                slicedData.map((lead,index) => (
                  <tr
                  key={lead.id}
                  className={`hover:bg-blue-300  ${index % 2 === 0 ? 'bg-gray-300' : 'bg-white'}`}
                  onClick={() => handleRowClick(lead.id)}
                >
                    <td className="px-5 center py-2 border border-gray-400">
                      <label className="inline-flex text-center">
                        <input
                          type="checkbox"
                          className="checkbox w-4 h-4"
                          checked={selectedData.includes(lead.id)}
                          onChange={() => handleCheckboxChange(lead.id)}
                        />
                      </label>
                    </td>
                    {data?.data?.map((value) => (
                      <td key={value.id} className="py-2 whitespace-nowrap  border border-gray-400">
                        {lead[value.name]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                currentData.map((lead,index) => (
                  <tr
                  key={lead.id}
                  className={`hover:bg-blue-300 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-300'}`}
                  onClick={() => handleRowClick(lead.id)}
                >
                    <td className="px-5 center py-2  border border-gray-400">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="checkbox w-4 h-4"
                          checked={selectedData.includes(lead.id)}
                          onChange={() => handleCheckboxChange(lead.id)}
                        />
                      </label>
                    </td>
                    {data?.data?.map((value) => (
                      <td key={value.id} className="py-2 px-3 whitespace-nowrap text-center  border border-gray-400">
                        {lead[value.name]}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>

          </table>
        </div>
      </div>
      <div className='flex-auto justify-between'>
        <div className='flex items-center justify-between '>
          <div className='pl-20'>
            <button className='bg-blue-500 p-1 rounded hover:rounded-xl m-5'
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaArrowLeft />
            </button>

            <span className='font-bold'>
              Page {currentPage} of {totalPages}
            </span>

            <button className=" bg-blue-500 p-1 rounded hover:rounded-xl m-5"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaArrowRight />
            </button>
          </div>
          <div className="text-right px-10 py-2">
            <button onClick={saveData} className="relative rounded px-5 py-2.5 overflow-hidden group bg-green-700 relative hover:bg-gradient-to-r hover:from-green-700 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300">
              <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
              <span className="relative">assign</span>
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AllocationForm;