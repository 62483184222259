import React from 'react';
import QRCode from 'react-qr-code';
import RainDot from "../../../assets/visionGroup.webp"
const PrintHeader = ({ from, singleData }) => {

  return (
    <div className="grid grid-cols-3 items-center   py-1 pr-5">
      <div className="flex items-center justify-start">
        <img src={RainDot} alt="raindot-logo" className="h-20 w-20 " />
        <h1 className="font-bold text-2xl text-green-700 ">{from.aliasName}</h1>
      </div>
      <div className="flex justify-center">
        <h1 className="font-bold text-2xl text-sky-700 ml-5">SALES INVOICE</h1>
      </div>
      <div className="flex justify-end">
        <h1 className=" py-1">
          <QRCode value={singleData?.data?.docId} size={60} />
        </h1>
      </div>
    </div>

  );
};

export default PrintHeader;
