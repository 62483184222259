import React from 'react';
import ProductionDeliveryDetails from './ProductionDeliveryDetails';
import TaxDetails from './TaxDetails';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
require('number-to-text/converters/en-in');
const numberToText = require('number-to-text');

const PrintDetail = ({ singleData, from, supplierData, taxDetails, taxTemplateId }) => {
  const gstNo = (supplierId) => {
    const matchingSupplier = supplierData.find(
      (supplier) => supplier.id === supplierId
    );

    return matchingSupplier || null;
  };

  return (
    <>
      <div className='grid grid-cols-2 bg-sky-800 text-white border-t p-1 border-gray-500 pl-3'>
        <div>VENDOR:</div>
        <div className='text-center'>DELIVERY TO:</div>
      </div>

      <div className='grid grid-cols-3 bg-gray-100 border-gray-500'>
        <div className='text-start p-1 w-full'>
          <div className='w-full text-center p-1'>
            <h3 className='font-bold text-green-700 text-lg'>{from.name}</h3>
            <h3 className='text-xs'>{from.address}</h3>
            <h3 className='text-xs'>{from.near}</h3>
            <h3 className='text-xs'>{from.pinCode}</h3>
            <h3 className='text-xs'>GSTNO: {from.gstNo}</h3>
            <h3 className='text-xs'>Place of Supply: {from.PlaceofSupply}</h3>
          </div>
        </div>
        <div className='flex bg-gray-100 border-l border-t border-gray-500'>
          <div className='p-3 flex-1'>
            <div className='grid grid-cols-2 gap-2'>
              <div className='text-left pl-3 bg-green-500 text-white rounded-xl p-1 text-xs'>PO NO:</div>
              <div className='text-right pr-3 bg-green-500 text-white rounded-xl p-1 text-xs'>{singleData?.data?.docId}</div>
              <div className='text-left pl-3 text-xs'>Delivery Date:</div>
              <div className='text-right pr-3 text-xs'>{getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</div>
              <div className='text-left pl-3 text-xs'>Customer:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.name : '-'}</div>
              <div className='text-left pl-3 text-xs'>GST Number:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.gstNo : '-'}</div>
            </div>
          </div>
        </div>
        <div className='flex bg-gray-100 border-l border-r border-t border-gray-500'>
          <div className='p-2 flex-1 text-justify'>
            <div className='grid grid-cols-2 gap-1'>
              <div className='text-xs'>Contact Person:</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactPersonName : '-'}</div>
              <div className='text-xs'>Contact:</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactMobile : '-'}</div>
              <div className='text-xs'>Address</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.address : '-'}</div>
              <div className='text-xs'>City</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.City?.name : '-'}</div>
              <div className='text-xs'>Pincode</div>
              <div className='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.pincode : '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ProductionDeliveryDetails productionDeliveryDetails={singleData?.data?.SalesBillItems || []} date={singleData?.data?.createdAt || []} />
      </div>

      <div className='flex justify-between p-1'>
  <div className="bg-white rounded-lg shadow border border-gray-500 p-3 w-56">
    <h3 className="font-bold text-md text-center mb-1 bg-green-500 text-white p-1 rounded">Bank Details</h3>
    <div className="text-left text-xs">
      <p><strong>Account name:</strong> VISION GROUPS</p>
      <p><strong>Bank name:</strong> Kotak Mahindra Bank Limited</p>
      <p><strong>Account number:</strong> 7546172246</p>
      <p><strong>IFSC:</strong> KKBK0008655</p>
      <p><strong>Branch Address:</strong> Coimbatore-Uppanakara Street</p>
      <p><strong>UPI ID:</strong> 8754063501@kotak</p>
    </div>
  </div>

  {singleData?.data?.SalesBillItems && singleData.data.SalesBillItems.some(item => item.tax > 0) && (
    <table className='border-l border-t border-gray-500 text-center border-spacing-2 border border-slate-400 w-56 bg-white rounded-lg shadow'>
      <tbody>
        <tr className='border border-gray-500'>
          <th className='p-1 bg-green-500 text-xs text-white' colSpan={2}>Tax Details</th>
        </tr>
        <TaxDetails
          items={(singleData?.data?.SalesBillItems || []).map(i => ({ ...i, taxPercent: i.tax }))}
          taxTemplateId={taxTemplateId}
          productionDeliveryDetails={singleData?.data?.SalesBillItems || []}
          discountType="percentage"
          discountValue={0}
        />
        <tr className='border border-gray-500 text-xs'>
          <td className='table-data p-1'>TAX RATE</td>
          <td className='table-data p-1'>{(parseFloat(taxDetails.cgstAmount) + parseFloat(taxDetails.sgstAmount)).toFixed(2)}</td>
        </tr>
        <tr className='border border-gray-500 text-xs'>
          <td className='table-data p-1'>ROUND OFF</td>
          <td className='table-data p-1'>{parseFloat(taxDetails?.roundOffAmount).toFixed(2)}</td>
        </tr>
        <tr className='border border-gray-500 text-xs'>
          <td className='table-data p-1 bg-green-500 text-xs text-white'>NET AMOUNT</td>
          <td className='table-data p-1 text-xs bg-green-500 text-white'>{parseFloat(taxDetails?.netAmount).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  )}
</div>



      <div className='w-full'>
        <div className='w-full  flex items-center bg-sky-800 p-2 text-white'>
          <h1 className=' text-xs pl-3 tracking-wider uppercase'>Amount in words:</h1>
          <span className='text-xs uppercase'>
            {numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
          </span>
        </div>
      </div>

      <div className='w-full bg-green-100 border-r border-gray-500 p-3'>
        <div className='flex items-center'>
          <svg className='w-5 h-5 text-green-500 mr-2' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 16h-1v-4h-1m0-4h.01M6.938 7h.01M6.938 21h.01M4 6h16M4 10h16m-1 4h-1m0 4h-1m0-4h-1m0 4h-1m0-4h-1m0 4h-1' />
          </svg>
          <h2 className='text-green-700 text-lg font-bold'>Terms and Conditions</h2>
        </div>
        <ul className='list-disc list-inside text-sm'>
          <li>One year warranty for any manufacturing defects from the date of supply/invoice/installation of GPS Device whichever is earlier. (For fault other than manufacturing defects, the repair & re-installation cost will be borne by the user)</li>
          <li>Taxes are applicable as per standards</li>
          <li>From next year, data charges will be charged at Rs.1500/2000/2500 Per Device Per Annum excluding taxes if any, subject to change with prior notice</li>
        </ul>
      </div>
    </>
  );
};

export default PrintDetail;
