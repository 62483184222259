import React, { useEffect, useState } from 'react';
import { useGetStockByIdQuery } from '../../../redux/CrmServices/StockService';

const StockQty = ({ productId, readOnly, index, setPoBillItems,id,qty }) => {
    const [quantity, setQuantity] = useState(0);
    
    const { data: stockData, isLoading } = useGetStockByIdQuery(
        { 
            params: {
                productId, 
            }   
        }, 
        { 
            skip: !productId 
        }
    );
    console.log(qty,"qty")
    

    useEffect(() => {
        if (stockData && stockData.data) {
            const stockQuantities = stockData.data.stockQty;
            setQuantity(stockQuantities);
        }
    }, [stockData]);

    useEffect(() => {
        setPoBillItems((prev) => {
            const newPrev = structuredClone(prev)
            newPrev[index].stockQty = id? qty: quantity;
            return newPrev;
        });
    }, [quantity, index, setPoBillItems]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <td className='table-data'>
            <input
                type="number"
                className="text-right rounded py-1 px-1 table-data-input"
                id={`quantity-${index}`}
                value={id? qty:quantity}
                disabled={readOnly}
                onChange={(e) => setQuantity(e.target.value)}
            />
        </td>
    );
};

export default StockQty;
