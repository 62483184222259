import React, { useEffect, useState, useRef, useCallback } from "react";

import secureLocalStorage from "react-secure-storage";
import {
    useGetBuyerQuery,
    useGetBuyerByIdQuery,
    useAddBuyerMutation,
    useUpdateBuyerMutation,
    useDeleteBuyerMutation,
} from "../../../redux/CrmServices/BuyerMasterService";

import { useGetCityQuery } from "../../../redux/services/CityMasterService";

import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, DropdownInput, CheckBox, RadioButton, TextArea, DateInput, MultiSelectDropdown } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, dropDownListMergedObject, multiSelectOption } from '../../../Utils/contructObject';
import moment from "moment";
import Modal from "../../../UiComponents/Modal";

import { Loader } from '../../../Basic/components';
import { useDispatch } from "react-redux";
import { findFromList } from "../../../Utils/helper";


const MODEL = "Buyer Master";


export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);

    const [id, setId] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [name, setName] = useState("");
    const [aliasName, setAliasName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [buyer, setBuyer] = useState("");
    const [sAddress, setSAddress] = useState("");
    const [cinNo, setCinNo] = useState("");
    const [addressProof, setAddressProof] = useState("");
    const [wifeName, setWifeName] = useState("");
    const [code, setCode] = useState("");
    const [address, setAddress] = useState("");
    const [refaddress, setRefAddress] = useState("");
    const [refaddress1, setRefAddress1] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [relative, setRelative] = useState("");
    const [relative1 , setRelative1] = useState("")
    const [gstNo, setGstNo] = useState("");
    const [costCode, setCostCode] = useState("");
    const [contactMobile, setContactMobile] = useState('');
    const [contactMobile1, setContactMobile1] = useState('');

    const [seller, setSeller] = useState("");
    const [motherName, setMotherName] = useState("");
    const [Mail, setMail] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [active, setActive] = useState(true);

    const [searchValue, setSearchValue] = useState("");

    const childRecord = useRef(0);
    const dispatch = useDispatch()


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
  
    const userId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userId"
    )
    const params = {
        companyId
    };
    const { data: cityList, isLoading: cityLoading, isFetching: cityFetching } =
        useGetCityQuery({ params });

   

    const { data: allData, isLoading, isFetching } = useGetBuyerQuery({ params, searchParams: searchValue });

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetBuyerByIdQuery(id, { skip: !id });

    const [addData] = useAddBuyerMutation();
    const [updateData] = useUpdateBuyerMutation();
    const [removeData] = useDeleteBuyerMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        setPhoneNo(data?.phoneNo ? data?.phoneNo : "");
        setName(data?.name ? data?.name : "");
         
         setAliasName(data?.aliasName ? data?.aliasName : "");

        setAddress(data?.address ? data?.address : "");
        setRefAddress(data?.references ? data?.references.map(item=>item.refAddress) : "");
        setRefAddress1(data?.references ? data?.references.map(item=>item.refAddress1) : "");

        setBuyer(data?.seller ? data?.seller.map(item=>item.buyer) : "");
        setSAddress(data?.seller ? data?.seller.map(item=>item.sAddress) : "");
        setCinNo(data?.cinNo ? data?.cinNo : "");
        setAddressProof(data?.seller ? data?.seller.map(item=>item.addressProof) : "");
        setCinNo(data?.cinNo ? data?.cinNo : "");
        setRelative(data?.references ? data?.references.map(item=>item.relative) : "");
        setRelative1(data?.references ? data?.references.map(item=>item.relative1) : "");
        setGstNo(data?.gstNo ? data?.gstNo : "");
        setCostCode(data?.costCode ? data?.costCode : "");
        setSeller(data?.seller ? data?.seller.map(item=>item.seller) : "");
        setCode(data?.code ? data?.code : "");
        setPincode(data?.pincode ? data?.pincode : "");
        setWifeName(data?.wifeName ? data?.wifeName : "");
        setMotherName(data?.motherName ? data?.motherName : "");
        setCity(data?.cityId ? data?.cityId : "");
        setFatherName((data?.fatherName ? data.fatherName : ""));
        setMail((data?.email ? data.email : ""));
        setActive(id ? (data?.active ? data.active : false) : true);
        setContactMobile1(data?.references ? data?.references.map(item=>item.contactMobile) : "");
        setContactMobile(data?.references ? data?.references.map(item=>item.contactMobile1) : "");
      
    }, [id]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        name,Mail, fatherName, code, aliasName,  address,refaddress,refaddress1, cityId: city, pincode, phoneNo, buyer, sAddress, seller, cinNo,
        addressProof, motherName, wifeName, relative,relative1, gstNo,  costCode,contactMobile,contactMobile1,
        active, companyId,
      
        id, userId
    }

    const validateData = (data) => {
        return data.name 
      
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: data }).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            dispatch({
                type: `accessoryItemMaster/invalidateTags`,
                payload: ['AccessoryItemMaster'],
            });
            dispatch({
                type: `CityMaster/invalidateTags`,
                payload: ['City/State Name'],
            });
            dispatch({
                type: `CurrencyMaster/invalidateTags`,
                payload: ['Currency'],
            });
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

   
    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
      

        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }


    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                dispatch({
                    type: `accessoryItemMaster/invalidateTags`,
                    payload: ['AccessoryItemMaster'],
                });
                setId("");
                dispatch({
                    type: `CityMaster/invalidateTags`,
                    payload: ['City/State Name'],
                });
                dispatch({
                    type: `CurrencyMaster/invalidateTags`,
                    payload: ['Currency'],
                });
                syncFormWithDb(undefined);
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setReadOnly(false);
        setForm(true);
        setSearchValue("");
        setId("");
        syncFormWithDb(undefined);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = ["Name", "Alias Name"]
    const tableDataNames = ["dataObj.name", 'dataObj.aliasName']


    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    
    if ( !cityList || cityFetching || cityLoading) {
        return <Loader />
    }

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
          
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2">
                    <div className="col-span-3 grid md:grid-cols-2 border h-[520px] overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border'>
                            <div className='mr-1 md:ml-2'>
                               
                                <fieldset className='frame my-1 flex'>
                                    <legend className='sub-heading'>Official Details</legend>
                                    <div className='flex flex-col justify-start gap-4 mt- flex-1'>
                                        <TextInput name="Buyer Code" type="text" value={code} setValue={setCode} readOnly={readOnly}  disabled={(childRecord.current > 0)} />
                                        <TextInput name="Buyer Name" type="text" value={name} 
                                        setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)}
                                          onBlur={(e) => {
                                              if(aliasName) return
                                              setAliasName(e.target.value)
                                            }
                                        } />
                                        <TextInput name="Alias Name" type="text"  value={aliasName} setValue={setAliasName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextArea name="Address" value={address} setValue={setAddress} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <DropdownInput name="City/State Name" options={dropDownListMergedObject(id ? cityList.data : cityList.data.filter(item => item.active), "name", "id")} value={city} setValue={setCity} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Phone No" type="Phone No" value={phoneNo} setValue={setPhoneNo} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Pincode" type="number" value={pincode} setValue={setPincode} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Mail" type="text" readOnly={readOnly} value={Mail} setValue={setMail} />
                                        <TextInput name="Father Name" type="text" readOnly={readOnly} value={fatherName} setValue={setFatherName} />
                                        <TextInput name="Mother Name" type="text" value={motherName} setValue={setMotherName} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="wife Name" type="text" value={wifeName} setValue={setWifeName} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                    </div>
                                </fieldset>
                            </div>
                            <div className='mr-1'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Reference</legend>
                                    <div className='grid grid-cols-1 gap-2 my-2'>
                                     
                                        <TextInput name="Ref 1" type="text" value={relative} setValue={setRelative} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Contact Mobile" type="text" value={contactMobile} setValue={setContactMobile} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextArea name="RefAddress" value={refaddress} setValue={setRefAddress} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Ref 2" type="text" value={relative1} setValue={setRelative1} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Contact Mobile" type="text" value={contactMobile1} setValue={setContactMobile1} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextArea name="RefAddress" value={refaddress1} setValue={setRefAddress1} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                    </div>
                                </fieldset>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Buyer & seller Info</legend>
                                    <div className='grid grid-cols-1 gap-2 my-2'>
                                        <TextInput name="Buyer Name" type="text" value={buyer} setValue={setBuyer} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Seller Name" value={seller} setValue={setSeller} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextArea name="Address" type="text" value={sAddress} setValue={setSAddress} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Address Proof" type="text" value={addressProof} setValue={setAddressProof} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
