import React from 'react';


const PrintFooterOnly = () => (
    
    
      <div className='flex justify-between p-1 '>

        <h1 className='pr-3'>Approved By</h1>
      </div>



);

export default PrintFooterOnly;
