import React from 'react'

const   ExcelFields = ({ header, currentSelectedExcelField, setCurrentSelectedExcelField }) => {
    return (
        <div className="flex border border-black h-full overflow-auto w-1/2">
            <table className="border border-gray-600 text-xs w-full">
                <thead>
                    <tr>
                        <th className="table-data border border-gray-600 text-left px-1">
                            Select
                        </th>
                        <th className="table-data border border-gray-600 text-left px-1">
                            Excel Data
                        </th>
                    </tr>
                </thead>
                <tbody className="overflow-y-auto border border-gray-600">
                    {header.map((value, index) => {
                        const isSelected = currentSelectedExcelField === value;
                        return (
                            <tr
                                key={index}
                                className={`border border-black ${isSelected ? 'bg-orange-100' : ''}`}
                            >
                                <th className="border border-black">
                                    <input
                                        className="cursor-pointer"
                                        type="radio"
                                        checked={isSelected}
                                        onChange={() => { setCurrentSelectedExcelField(value) }}
                                    />
                                </th>
                                <td className="border border-black">
                                    <input
                                        type="text"
                                        className={`border w-full h-6 px-1 focus:outline-none ${isSelected ? 'bg-orange-100' : 'focus:ring-2 focus:ring-blue-500'}`}
                                        value={value.name}
                                        readOnly
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ExcelFields
