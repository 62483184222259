import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LOANTENURE_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const loanTenureApi = createApi({
  reducerPath: "loanTenure",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["LoanTenure"],
  endpoints: (builder) => ({
    getLoanTenure: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: LOANTENURE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LOANTENURE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LoanTenure"],
    }),
  
    getLoanTenureById: builder.query({
      query: (id) => {
        return {
          url: `${LOANTENURE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LoanTenure"],
    }),
    addLoanTenure: builder.mutation({
      query: (payload) => ({
        url: LOANTENURE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["LoanTenure"],
    }),
    updateLoanTenure: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LOANTENURE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LoanTenure"],
    }),
    deleteLoanTenure: builder.mutation({
      query: (id) => ({
        url: `${LOANTENURE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LoanTenure"],
    }),
  }),
});

export const {
  useGetLoanTenureQuery,
  useGetLoanTenureByIdQuery,
  useAddLoanTenureMutation,
  useUpdateLoanTenureMutation,
  useDeleteLoanTenureMutation,
} = loanTenureApi;

export default loanTenureApi;
