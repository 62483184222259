// Table.js
import React, { useRef } from 'react';
import { useGetBuyerQuery } from '../../../redux/CrmServices/BuyerMasterService';
import secureLocalStorage from 'react-secure-storage';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Table = () => {
  const tableRef = useRef(null);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  );
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  );
  const params = { branchId, companyId, references: true };
  
  const { data: supplierList } = useGetBuyerQuery({ params });
  
  const supplierData = supplierList?.data || [];
  console.log(supplierData, "supplierData");

  const downloadPDF = () => {
    const input = tableRef.current;
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = 0;

      while (position < imgHeight) {
        pdf.addImage(imgData, 'PNG', 0, -position, imgWidth, imgHeight);
        position += pageHeight;
        if (position < imgHeight) {
          pdf.addPage();
        }
      }

      pdf.save("download.pdf");
    });
  };

  return (
    <div className="flex flex-col">
      <button
        className="mb-4 px-4 py-2 bg-blue-800 text-white rounded"
        onClick={downloadPDF}
      >
        Download PDF
      </button>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8" ref={tableRef}>
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow-lg rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Customer
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Refer1 
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Contact No
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Address
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Refer2 
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Contact No
                  </th>
                  <th scope="col" className="px-5 py-3 bg-gray-200 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">
                    Address
                  </th>
                </tr>
              </thead>
              <tbody>
                {supplierData.map((dataObj, index) => (
                  <tr key={index} className="bg-white hover:bg-green-100">
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{dataObj.name}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{dataObj.references.map(item => item.relative).join(', ')}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                        <span className="relative">{dataObj.references.map(item => item.contactMobile).join(', ')}</span>
                      </span>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{dataObj.references.map(item => item.refAddress).join(', ')}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{dataObj.references.map(item => item.relative1).join(', ')}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                        <span className="relative">{dataObj.references.map(item => item.contactMobile1).join(', ')}</span>
                      </span>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{dataObj.references.map(item => item.refAddress1).join(', ')}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
