import React from 'react';
import moment from 'moment';

const ProductionDeliveryDetails = ({ productionDeliveryDetails, date }) => {
  const addMonths = (months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + parseInt(months, 10));
    return newDate;
  };
  return (
    <fieldset>
      <div className={`relative w-full overflow-y-auto `}>
        <table className="text-xs border-t border-b border-gray-500 table-auto w-full">
          <thead className='bg-green-500 text-black text-white uppercase'>
            <tr className='border border-gray-500 p-2'>
              <th className="table-data w-2 text-center p-2  p-2">S.no</th>
              <th className="table-data w-32 pl-1">Item & Description<span className="text-red-500 p-2"></span></th>
              <th className="table-data pl-1">HSN/SAC<span className="text-red-500 p-2"></span></th>
              {productionDeliveryDetails.some(item => item?.simNo) && (
                <th className="table-data">simNo<span className="text-red-500 p-2"></span></th>
              )}
              {productionDeliveryDetails.some(item => item?.validationMonths) && (
                <>
                  <th className='table-data pl-1'>validation Date</th>
                </>
              )}
              {productionDeliveryDetails.some(item => item?.imeiNo) && (
                <th className="table-data">imeiNo<span className="text-red-500 p-2"></span></th>
              )}
              {productionDeliveryDetails.some(item => item?.vehicleNo) && (
                <th className="table-data pl-1">VehicleNo<span className="text-red-500 p-2"></span></th>
              )}
              <th className="table-data pl-1">Qty<span className="text-red-500 p-2"></span></th>
              <th className="table-data pl-1">Price<span className="text-red-500 p-2"></span></th>
              {productionDeliveryDetails.some(item => item.tax > 0) && (
                <th className="table-data w-28 pl-1">Tax <span className="text-red-500 p-0.5"></span></th>
              )}
              <th className="table-data w-24 p-0.5">Amount</th>
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {productionDeliveryDetails.map((row, index) => (
              <tr key={index} className={`w-full ${index % 2 === 0 ? 'bg-white' : 'bg-gray-200'} hover:bg-gray-200`}>
                <td className='table-data p-2 text-center'>{index + 1}</td>
                <td className='table-data font-semibold'>
                  <div className='text-center pt-2 pb-1'>{row.Product && row.Product.name}</div>
                  <div className='text-xs font-thin overflow-hidden w-40 text-wrap pl-1'>
                    {row.Product?.description || ""}
                  </div>
                </td>
                <td className='table-data p-2'>
                  {row.Product?.hsn || "*"}
                </td>
                {productionDeliveryDetails.some(item => item.simNo) && (<td className='table-data p-2'>{row.simNo}</td>)}
                {productionDeliveryDetails.some(item => item.validationMonths) && (
                  <td className='table-data ' style={{ verticalAlign: 'middle' }}>
                    {moment(addMonths(row?.validationMonths ? row?.validationMonths : 0)).format("DD-MM-YYYY")}
                  </td>
                )}
                {productionDeliveryDetails.some(item => item.imeiNo) && (<td className='table-data p-2'>{row.imeiNo}</td>)}
                {productionDeliveryDetails.some(item => item.vehicleNo) && (
                  <td className='table-data ' style={{ width: '150px' }}>{row.vehicleNo}</td>
                )}
                <td className='table-data p-2'>
                  {row.qty}
                </td>
                <td className='table-data p-2'>
                  {parseFloat(row.price || 0).toFixed(2)}
                </td>
                {productionDeliveryDetails.some(item => item.tax > 0) && (
                  <td className='table-data p-2'>{row.tax}%</td>)}
                <td className='table-data p-2'>{(row.price * row.qty).toFixed(2)}</td>
              </tr>
            ))}
            <tr className='border border-gray-500'>
              <th className='table-data text-center px-1 font-bold text-xs py-2' colSpan={productionDeliveryDetails.some(item => item.tax > 0) ? 12 : 12}>TAXABLE AMOUNT</th>
              <td className='px-1 h-8 text-center font-semibold text-center absolute right-0 pr-3 pt-2'>
                {parseFloat(
                  productionDeliveryDetails.reduce((a, c) => a + (parseFloat(c.price || 0) * parseFloat(c.qty)), 0)
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>

        </table>

      </div>
    </fieldset>
  )
}

export default ProductionDeliveryDetails  