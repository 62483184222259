import React, { useEffect } from 'react';
import { TextArea, DropdownInput } from '../../../Inputs';
import { PaymentDet } from '../../../Utils/DropdownData';

function Popup({ handleClose, readOnly, payType, setPayType, setPoBillItems, inputValue, setInputValue, description, setDescription, activeTab, setActiveTab }) {
    
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-8 rounded shadow-lg relative w-96">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-red-600 text-3xl hover:text-red-900"
        >
          &times;
        </button>
        <div className="mb-4">
          <button
            className={`px-4 py-2 ${activeTab === 'description' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('description')}
          >
            Description
          </button>
          <button
            className={`px-4 py-2 ml-2 ${activeTab === 'payment' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('payment')}
          >
            Payment Details
          </button>
        </div>
        {activeTab === 'description' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Description</h2>
            <p>Here is the description of the item or service.</p>
            <TextArea name="Description" type="text" value={description} setValue={setDescription} readOnly={readOnly} />
          </div>
        )}
        {activeTab === 'payment' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
            <p>Here are the payment details.</p>
            <DropdownInput name="PaymentDet" options={PaymentDet} value={payType} setValue={setPayType} setPoBillItems={setPoBillItems} required={true} readOnly={readOnly} />
            <div className="flex bg-gray-100 justify-center mt-2">
              <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter amount"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Popup;
