import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetLoanTenureQuery } from "../../../redux/CrmServices/LoanTenureService";
import secureLocalStorage from "react-secure-storage";
import { FcDownload } from "react-icons/fc";

const PdfFormat = ({purData}) => {
  
  const tableRef = useRef(null);

  const downloadPDF = () => {
    const input = tableRef.current;
    html2canvas(input).then((canvas) => {
      console.log(canvas); 
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const today = new Date().toISOString().split("T")[0];

  const topThreeData = purData.slice(0, 5);

  return (
    <div className="max-w-full h-full mx-auto p-4  shadow-md rounded-lg">
      <div className="mb-6 border border-gray-300  bg-gray-100 p-4 rounded-lg overflow-x-auto">
        <table ref={tableRef} className="w-full bg-white table-auto">
          <thead>
            <tr className="bg-sky-800 text-white ">
              <th className="border px-4 py-2 font-thin">Name</th>
              <th className="border px-4 py-2 font-thin">Date</th>
              <th className="border px-4 py-2 font-thin">Contact Number</th>
              <th className="border px-4 py-2 font-thin">Address</th>
              <th className="border px-4 py-2 font-thin">Pincode</th>
              <th className="border px-4 py-2 font-thin">Bank Name</th>
              <th className="border px-4 py-2 font-thin">Loan Type</th>
              <th className="border px-4 py-2 font-thin">Loan Amount</th>
              <th className="border px-4 py-2 font-thin">Loan End Date</th>
              <th className="border px-4 py-2 font-thin">EMI Status</th>
            </tr>
          </thead>
          <tbody>
            {topThreeData.map((item, index) => (
              <tr key={index} className="border">
                <td className="border px-4 py-2">{item.Buyer.name}</td>
                <td className="border px-4 py-2">
                  {new Date(item.createdAt).toLocaleDateString()}
                </td>
                <td className="border text-green-800 font-bold px-4 py-2">
                  {item.Buyer.phoneNo}
                </td>
                <td className="border px-4 py-2">
                  {item.Buyer.address || "N/A"}
                </td>
                <td className="border px-4 py-2">
                  {item.Buyer.pincode || "N/A"}
                </td>
                <td className="border px-4 py-2 bg-red-100">
                  {item.LoanTenureDetail && item.LoanTenureDetail.length > 0
                    ? item.LoanTenureDetail.map((detail, index) => (
                        <div key={index}>{detail.Bank.name}</div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2 bg-green-100">
                  {item.LoanTenureDetail && item.LoanTenureDetail.length > 0
                    ? item.LoanTenureDetail.map((detail, index) => (
                        <div key={index}>{detail.LoanType.name}</div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2 bg-blue-100">
                  {item.LoanTenureDetail && item.LoanTenureDetail.length > 0
                    ? item.LoanTenureDetail.map((detail, index) => (
                        <div key={index}>{detail.loanAmount}</div>
                      ))
                    : "N/A"}
                </td>

                <td className="border px-4 py-2">
                  {item.LoanTenureDetail && item.LoanTenureDetail.length > 0
                    ? item.LoanTenureDetail.map((detail, index) => (
                        <div key={index}>
                          {detail.lend
                            ? new Date(detail.lend).toISOString().split("T")[0]
                            : ""}
                        </div>
                      ))
                    : "N/A"}
                </td>

                <td
                  className={`border px-4 py-2 ${
                    item.lend <= today ? "text-blue-500" : "text-green-500"
                  }`}
                >
                  {item.lend <= today ? "Not Active" : "Active"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-end mt-4">
      <button
    className="border-2 bg-green-100 border-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    onClick={downloadPDF}
    title="Download"
  >
    <FcDownload />
  </button>

      </div>
    </div>
  );
};

export default PdfFormat;
