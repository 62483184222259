import React from 'react'
import logo from '../../../assets/pin.png'

const Loader = () => {
  return (
    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
      <img src={logo} className='w-24 h-24 animate-ping' alt='Loading...' />
    </div>
  );
}


export default Loader

