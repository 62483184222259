import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PRODUCT_SERVICE_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ProductServiceApi = createApi({
  reducerPath: "productService",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["ProductService"],
  endpoints: (builder) => ({
    getProductService: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: PRODUCT_SERVICE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PRODUCT_SERVICE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ProductService"],
    }),
  
    getProductServiceById: builder.query({
      query: (id) => {
        return {
          url: `${PRODUCT_SERVICE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ProductService"],
    }),
    addProductService: builder.mutation({
      query: (payload) => ({
        url: PRODUCT_SERVICE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["ProductService"],
    }),
    updateProductService: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${PRODUCT_SERVICE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ProductService"],
    }),
    deleteProductService: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_SERVICE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductService"],
    }),
  }),
});

export const {
  useGetProductServiceQuery,
  useGetProductServiceByIdQuery,
  useAddProductServiceMutation,
  useUpdateProductServiceMutation,
  useDeleteProductServiceMutation,
} = ProductServiceApi;

export default ProductServiceApi;
