import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BUYER_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const buyerMasterApi = createApi({
  reducerPath: "buyerMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["Buyer"],
  endpoints: (builder) => ({
    getBuyer: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: BUYER_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: BUYER_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Buyer"],
    }),
    getBuyerById: builder.query({
      query: (id) => {
        return {
          url: `${BUYER_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Buyer"],
    }),
    addBuyer: builder.mutation({
      query: (payload) => ({
        url: BUYER_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Buyer"],
    }),
    updateBuyer: builder.mutation({
      query: ({id, body}) => {
        return {
          url: `${BUYER_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Buyer"],
    }),
    deleteBuyer: builder.mutation({
      query: (id) => ({
        url: `${BUYER_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Buyer"],
    }),
  }),
});

export const {
  useGetBuyerQuery,
  useGetBuyerByIdQuery,
  useAddBuyerMutation,
  useUpdateBuyerMutation,
  useDeleteBuyerMutation,
} = buyerMasterApi;

export default buyerMasterApi;
