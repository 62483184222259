import React from 'react';
import ProductionDeliveryDetails from './ProductionDeliveryDetails';
import TaxDetails from './TaxDetails';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
require('number-to-text/converters/en-in');
const numberToText = require('number-to-text')

const PrintDetail = ({ singleData, from, supplierData, taxDetails, taxTemplateId }) => {
  const gstNo = (supplierId) => {
    const matchingSupplier = supplierData.find(
      (supplier) => supplier.id === supplierId
    );

    return matchingSupplier || null;
  };

  return (
    <>
      <div className='grid grid-cols-2 bg-sky-800 text-white  p-1 border-gray-500 pl-3'>
        <div>
          VENDOR:
        </div>
        <div className='text-center'>
          DELIVERY TO:
        </div>
      </div>

      <div className='grid grid-cols-3 bg-gray-100 border-gray-500'>
        <div className='text-start p-1 w-full'>
          <div className='w-full text-center p-1'>
            <h3 className='font-bold text-green-700 text-lg'>{from.name}</h3>
            <h3 className='text-xs'>{from.address}</h3>
            <h3 className='text-xs'>{from.near}</h3>
            <h3 className='text-xs'>{from.pinCode}</h3>
            <h3 className='text-xs'>GSTNO: {from.gstNo}</h3>
            <h3 className='text-xs'>Place of Supply: {from.PlaceofSupply}</h3>
          </div>
        </div>
        <div className='flex bg-gray-100 border-l  border-gray-500'>
          <div className='p-3 flex-1'>
            <div className='grid grid-cols-2 gap-2'>
              <div className='text-left pl-3 bg-green-500 text-white rounded-xl p-1 text-xs'>PO NO:</div>
              <div className='text-right pr-3 bg-green-500 text-white rounded-xl p-1 text-xs'>{singleData?.data?.docId}</div>
              <div className='text-left pl-3 text-xs'>Delivery Date:</div>
              <div className='text-right pr-3 text-xs'>{getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</div>
              <div className='text-left pl-3 text-xs'>Customer:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.name : '-'}</div>
              <div className='text-left pl-3 text-xs'>GST Number:</div>
              <div className='text-right pr-3 text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.gstNo : '-'}</div>
            </div>
          </div>
        </div>
        <div class='flex bg-gray-100 border-l  border-gray-500'>
          <div class='p-2 flex-1 text-justify'>
            <div class='grid grid-cols-2 gap-1'>
            <div class='text-xs'>ContactPerson:</div>
              <div class='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactPersonName : '-'}</div>
              <div class='text-xs'>Contact:</div>
              <div class='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.contactMobile : '-'}</div>
              <div class='text-xs'>Address</div>
              <div class='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.address : '-'}</div>
              <div class='text-xs'>City</div>
              <div class='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.City?.name : '-'}</div>
              <div class='text-xs'>Pincode</div>
              <div class='text-xs'>{singleData?.data?.supplierId ? gstNo(singleData.data.supplierId)?.pincode : '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ProductionDeliveryDetails productionDeliveryDetails={singleData?.data?.SalesBillItems || []} date={singleData?.data?.createdAt || []} />
      </div>

      <div className='flex justify-end'>
        {singleData?.data?.SalesBillItems && singleData.data.SalesBillItems.some(item => item.tax > 0) && (
          <table className='border-l border-t border-gray-500 text-center border-spacing-2 border border-slate-400 w-60'>
            <tbody>
              <tr className='border border-gray-500'>
                <th className='p-2 bg-green-500 text-xs text-white' colSpan={2}>Tax Details</th>
              </tr>
              <TaxDetails
                items={(singleData?.data?.SalesBillItems || []).map(i => ({ ...i, taxPercent: i.tax }))}
                taxTemplateId={taxTemplateId}
                productionDeliveryDetails={singleData?.data?.SalesBillItems || []}
                discountType="percentage"
                discountValue={0}
              />
              <tr className='border border-gray-500 text-xs p-2'>
                <td className='table-data'>TAXRATE</td>
                <td className='table-data'>{(parseFloat(taxDetails.cgstAmount) + parseFloat(taxDetails.sgstAmount)).toFixed(2)}</td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1'>ROUNDOFF</td>
                <td className='table-data p-1'>{parseFloat(taxDetails?.roundOffAmount).toFixed(2)}</td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-500 text-xs text-white'>NET AMOUNT</td>
                <td className='table-data p-1 text-xs bg-green-500 text-white'>{parseFloat(taxDetails?.netAmount).toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div className='w-full'>
        <div className='w-full  flex items-center bg-sky-800 p-2 text-white'>
          <h1 className=' text-xs pl-3 tracking-wider uppercase'>Amount in words:</h1>
          <span className='text-xs uppercase'>
            {numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
          </span>
        </div>
      </div>
    </>
  );
};

export default PrintDetail;
